import { OrganizationalUnitList } from "./OrganizationalUnitList";
import { deleteOrganizationalUnit, OrganizationalUnitType } from "../../clients/organizationalunit.client";
import { OneCardGrid } from "../../components/grid/OneCardGrid";
import { DataTableSkeleton } from "../../components/skeletons/DataTableChartSkeleton";
import { useParams } from "react-router-dom";
import { messageError, messageSuccess } from "../../services/message.service";
import { TabsStyled } from "../../components/tabs/TabStyled";
import { getOrganizationUnitLabel } from "../../utils/organization.utils";
import { MESSAGE } from "../../utils/localization.utils";
import { useRedirect } from "../../hooks/router.hooks";
import { OrganizationalUnitResponseDTO } from "../../open-api";
import { Arr } from "../../utils/functional/array.utils";
import { isNotNull } from "../../utils/predicate";
import { ConditionalRender } from "../../components/ConditionalRender";
import { useIsManagedCView, WorkspaceRole } from "../../hooks/auth.hooks";
import { AddOrganizationalUnit } from "./AddOrganizationalUnit";
import { useState } from "react";
import { OrganizationalUnitDialogCreate } from "./dialogs/OrganizationalUnitDialogCreate";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import { css } from "@emotion/css";
import { useTheme } from "@material-ui/core";
import { AutomaticCustomerCreationProvider } from "./batch/AutomaticCustomerCreation.provider";
import { useFeatureToggle } from "../../hooks/ft.hooks";
import { FEATURES } from "../../app.config";
import DiscountsTable from "./components/DiscountsTable";

interface OrganizationalUnitPageTabbedProps {
  organizationalUnits: OrganizationalUnitResponseDTO[];
  refresh: () => void;
}

export function OrganizationalUnitPageTabbed({ organizationalUnits, refresh }: OrganizationalUnitPageTabbedProps) {
  const redirect = useRedirect();
  const CONVERT_FROM_CLOUD = "CONVERT_FROM_CLOUD";
  const DISCOUNTS = "DISCOUNTS";
  const { filterType } = useParams<{ filterType?: OrganizationalUnitType | "CONVERT_FROM_CLOUD" | "DISCOUNTS" }>();
  const [showCreate, setShowCreate] = useState(false);
  const theme = useTheme();
  const isManagedCViewUser = useIsManagedCView();
  const distinctTypes = (isManagedCViewUser ? [] : [CONVERT_FROM_CLOUD]).concat(
    Array.from(new Set(organizationalUnits.map((it) => it.type))).filter(isNotNull)
  );
  const useDiscountPerProduct = useFeatureToggle(FEATURES.discountPerProduct);
  if (useDiscountPerProduct) {
    distinctTypes.push(DISCOUNTS);
  }

  // redirect to first available filtertype if none is set.
  if (!filterType && distinctTypes.length > 0) {
    redirect(`/organizational-unit/${distinctTypes[0]}`);
  }

  const filteredUnits = organizationalUnits.filter((it) => it.type === filterType);

  // redirect if there are no units with the selected type:
  if (Arr.contains(OrganizationalUnitType, filterType) && filteredUnits.length === 0 && distinctTypes.length > 0) {
    redirect(`/organizational-unit/${distinctTypes[0]}`);
  }

  const handleChange = (value: number) => {
    const type = distinctTypes[value];
    redirect(`/organizational-unit/${type}`);
  };

  const handleDelete = (organizationalUnit: OrganizationalUnitResponseDTO) =>
    deleteOrganizationalUnit(organizationalUnit.id)
      .then(() => {
        messageSuccess(`Organization ${organizationalUnit.name} deleted`);
        refresh();
      })
      .catch(() => messageError(MESSAGE.ERROR.DELETE_ORGANIZATIONAL_UNIT));

  const handleClickEdit = (organizationalUnit: OrganizationalUnitResponseDTO) =>
    redirect(`/organizational-unit/unit/${organizationalUnit.id}`);

  if (!organizationalUnits) {
    return (
      <OneCardGrid>
        <DataTableSkeleton rowCount={10} />
      </OneCardGrid>
    );
  }

  const tabsLabels = distinctTypes.map((type) => {
    const [, label] = getOrganizationUnitLabel(type);
    return { label };
  });

  return (
    <>
      <TabsStyled
        handleChange={handleChange}
        tabValue={distinctTypes.indexOf(filterType ?? "INTERNAL")}
        tabs={tabsLabels}
      />

      {filterType != null && Arr.contains(OrganizationalUnitType, filterType) && (
        <>
          <OrganizationalUnitList
            organizationalUnits={filteredUnits}
            onDelete={handleDelete}
            onClick={handleClickEdit}
            type={filterType}
          />
          <ConditionalRender hasRole={[WorkspaceRole.ADMIN, WorkspaceRole.READ]}>
            <Fab
              aria-label={"Add customer"}
              className={css({
                position: "absolute",
                bottom: theme.spacing(2),
                right: theme.spacing(2),
              })}
              color={"primary"}
              onClick={() => setShowCreate(true)}
            >
              <Tooltip title={"Add organizational unit"} placement="left">
                <AddIcon />
              </Tooltip>
            </Fab>
          </ConditionalRender>
        </>
      )}

      {filterType === CONVERT_FROM_CLOUD && (
        <ConditionalRender hasRole={[WorkspaceRole.ADMIN, WorkspaceRole.READ]}>
          <AutomaticCustomerCreationProvider onManualCreate={() => setShowCreate(true)} onSuccess={refresh} />
        </ConditionalRender>
      )}

      {(filterType !== CONVERT_FROM_CLOUD || distinctTypes.length === 0) && (
        <ConditionalRender hasRole={[WorkspaceRole.ADMIN]}>
          <AddOrganizationalUnit onSuccess={(unit) => redirect(`/organizational-unit/unit/${unit.id}`)} />
        </ConditionalRender>
      )}

      {filterType === DISCOUNTS && (
        // todo check this condition
        <ConditionalRender hasRole={[WorkspaceRole.ADMIN, WorkspaceRole.READ]}>
          <DiscountsTable />
        </ConditionalRender>
      )}

      {showCreate && (
        <OrganizationalUnitDialogCreate
          toggle={() => {
            setShowCreate(!showCreate);
          }}
          onSuccess={(unit) => redirect(`/organizational-unit/unit/${unit.id}`)}
          open={showCreate}
        />
      )}
    </>
  );
}

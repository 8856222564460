import { ChangeEvent, useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import { Button, Checkbox, FormControlLabel, Link, Typography } from "@material-ui/core";
import {
  AZURE_ACCOUNT_TYPE,
  AZURE_CONFIG_TYPES,
  CA_CONSENT,
  createAzureCollector,
  DataSourceResponseDTO,
} from "../../../clients/collector.client";
import { useApiCall } from "../../../hooks/client.hooks";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { FormTitleField } from "../../../components/form/FormTitleField";
import { useForm } from "react-hook-form";
import { FormTextField } from "../../../components/form/FormTextField";
import { ButtonConfirm } from "../../../components/form/ButtonConfirm";
import { CollectorAzureInput } from "../../../open-api";
import { map, range } from "../../../utils/iterable.utils";
import { pipe } from "../../../utils/fp";
import { css } from "@emotion/css";
import { FormSwitchField } from "../../../components/form/FormSwitchField";
import { useFeatureToggle } from "../../../hooks/ft.hooks";
import { ControlledSelectSingleField, ControlledTextField } from "../../../components/form/FormComponents";
import { InformationLink } from "../../../components/Information";
import { deleteLocalStorageValue, getLocalStorageValue, setLocalStorageValue } from "../../../utils/localStorage.utils";
import { FEATURES } from "../../../app.config";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    justifyContent: "center",
  },
  container: {
    padding: theme.spacing(1),
  },
  form: {
    width: "100%",
  },
}));

interface AzureDataSourceFormProps {
  onSuccess: (ds: DataSourceResponseDTO) => void;
  onError: (msg: string) => void;
  onBack: () => void;
  onClose: () => void;
  buttonCancel: boolean;
}

export function AzureDataSourceForm({
  onSuccess,
  onError,
  onClose,
  onBack,
  buttonCancel = true,
}: AzureDataSourceFormProps) {
  const classes = useStyles();
  const [submit, isSubmitting] = useApiCall(createAzureCollector);
  const useAttributeFilter = useFeatureToggle(FEATURES.filterAttributes);

  const { handleSubmit, control, setValue, watch } = useForm<CollectorAzureInput>({
    defaultValues: {
      credentials: {
        accountType: AZURE_ACCOUNT_TYPE.CA_EA_CONNECTOR,
      },
    },
  });

  const [status, setStatus] = useState<string | null>(CA_CONSENT.STATUS.PENDING);
  const [isReaderRightSet, setIsReaderRightSet] = useState(false);
  const [adDomain, setAdDomain] = useState<string | null>(null);
  // Setup and clean up hook for CA connect
  useEffect(() => {
    setLocalStorageValue("connector.status", "PENDING");
    deleteLocalStorageValue("connector.adDomain");
    const handler = () => {
      if (status !== getLocalStorageValue("connector.status")) {
        setStatus(getLocalStorageValue("connector.status") ?? null);
      }
      if (adDomain !== getLocalStorageValue("connector.adDomain")) {
        setAdDomain(getLocalStorageValue("connector.adDomain") ?? null);
        setValue("credentials.adDomain", getLocalStorageValue("connector.adDomain") ?? "");
      }
    };
    window.addEventListener("storage", handler);
    return () => {
      deleteLocalStorageValue("connector.status");
      deleteLocalStorageValue("connector.adDomain");
      window.removeEventListener("storage", handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = (form: CollectorAzureInput) => {
    submit(form).then(onSuccess).catch(onError);
  };

  const selectedType = watch("credentials.accountType");

  const useIngramCA = useFeatureToggle(FEATURES.ingramConnect);

  const azureConfigTypes = useIngramCA
    ? AZURE_CONFIG_TYPES.concat({
        key: AZURE_ACCOUNT_TYPE.INGRAMCA,
        label: "Ingram Reseller",
      })
    : AZURE_CONFIG_TYPES;

  const handleIsReaderRightSetChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsReaderRightSet(event.target.checked);
  };

  return (
    <form onSubmit={handleSubmit(handleSave)} className={classes.form}>
      <DialogContent className={classes.content}>
        <Grid container spacing={2}>
          <FormTitleField text="Data source" />
          <ControlledTextField control={control} name={"name"} label={"Name"} fullWidth />
          <FormTitleField text="Choose your account type" />

          <ControlledSelectSingleField
            fullWidth
            options={azureConfigTypes.map((it) => ({ value: it.key, label: it.label }))}
            control={control}
            name={"credentials.accountType"}
            label={"Account type"}
          />

          {(selectedType === AZURE_ACCOUNT_TYPE.AZURE_INDIRECT ||
            selectedType === AZURE_ACCOUNT_TYPE.AZURE_INDIRECT_CONNECTOR) && (
            <>
              <FormTitleField text="Billing cycle" />
              <ControlledSelectSingleField
                fullWidth
                options={pipe(
                  range(1, 32),
                  (_) => map(_, (it) => ({ value: it, label: `${it}` })),
                  (_) => Array.from(_)
                )}
                MenuProps={{ classes: { paper: css({ maxHeight: "200px" }) } }}
                label="First day of billing cycle"
                control={control}
                name={"config.azureIndirect.billingCycleAnchorDate"}
              />
            </>
          )}

          <FormTitleField text="Credentials" />
          {(selectedType === AZURE_ACCOUNT_TYPE.CA_EA_CONNECTOR ||
            selectedType === AZURE_ACCOUNT_TYPE.AZURE_INDIRECT_CONNECTOR ||
            selectedType === AZURE_ACCOUNT_TYPE.M365_CONNECTOR) && (
            <>
              <FormTitleField text="Step 1" />
              {adDomain && <FormTextField name={"credentials.adDomain"} label="AD Domain" value={adDomain} />}
              {status === CA_CONSENT.STATUS.PENDING && (
                <Link
                  target="blank"
                  href={
                    selectedType === AZURE_ACCOUNT_TYPE.M365_CONNECTOR
                      ? CA_CONSENT.url1M365Connector()
                      : CA_CONSENT.url1CAConnector()
                  }
                >
                  Click here to give access to the application
                </Link>
              )}
              {adDomain && (
                <Typography color="textPrimary">Successfully received AD Domain, Continue with step 2</Typography>
              )}
              {adDomain && <FormTitleField text="Step 2" />}
              {status === CA_CONSENT.STATUS.VERIFYING && adDomain && (
                <Link
                  target="blank"
                  href={
                    selectedType === AZURE_ACCOUNT_TYPE.M365_CONNECTOR
                      ? CA_CONSENT.url2M365Connector(adDomain)
                      : CA_CONSENT.url2CAConnector(adDomain)
                  }
                >
                  Click here to give consent
                </Link>
              )}
              {selectedType !== AZURE_ACCOUNT_TYPE.M365_CONNECTOR && status === CA_CONSENT.STATUS.VERIFIED && (
                <>
                  <Typography color="textPrimary" display="initial">
                    Successfully given consent, Continue with step 3
                  </Typography>
                  <FormTitleField text="Step 3" />
                  <Typography display="initial">
                    Add read rights to the subscription(s)
                    <br />
                    <Link target="blank" href={CA_CONSENT.READ_RIGHTS_FAQ_URL}>
                      Click here to read instructions on how to add read rights
                    </Link>
                  </Typography>
                  <FormTitleField text="Step 4" />
                  <FormControlLabel
                    control={<Checkbox checked={isReaderRightSet} onChange={handleIsReaderRightSetChange} />}
                    label="All the reader rights are added to the subscriptions that need to be included in this data source."
                  />
                </>
              )}
              <ControlledTextField
                type="hidden"
                control={control}
                name="credentials.adDomain"
                id="credentials.adDomain"
              />
            </>
          )}
          {(selectedType === AZURE_ACCOUNT_TYPE.CSP || selectedType === AZURE_ACCOUNT_TYPE.INGRAMCA) && (
            <>
              {useAttributeFilter && (
                <FormSwitchField
                  setValue={setValue}
                  name="config.generic.includeAllData"
                  id="config.generic.includeAllData"
                  label={
                    <span>
                      Include all data from this data source{" "}
                      <InformationLink
                        url={
                          "https://c-facts.atlassian.net/servicedesk/customer/portal/3/topic/82ad38c8-b3d5-4cdb-acad-2057960af78f/article/914292737"
                        }
                      />
                    </span>
                  }
                  control={control}
                  confirmationDialogTitle="Filter collected data"
                  confirmationDialogContent="Are you sure you want to switch filtering setting?"
                  defaultValue={true}
                />
              )}

              <ControlledTextField control={control} name={"credentials.adDomain"} label={"AD Domain"} fullWidth />
              <ControlledTextField control={control} name={"credentials.appId"} label={"Application ID"} fullWidth />
              <ControlledTextField control={control} name={"credentials.appKey"} label={"Application key"} fullWidth />
            </>
          )}
          {selectedType === AZURE_ACCOUNT_TYPE.CSP && (
            <>
              <ControlledTextField
                control={control}
                name={"credentials.refreshToken"}
                label={"Refresh token"}
                fullWidth
              />
            </>
          )}
          {selectedType === AZURE_ACCOUNT_TYPE.INGRAMCA && (
            <>
              <FormTitleField text="Ingram Marketplace API" />
              <ControlledTextField
                control={control}
                name={"credentials.ingramMarketplace"}
                label={"Marketplace Location"}
                fullWidth
              />
              <ControlledTextField control={control} name={"credentials.ingramApiKey"} label={"API key"} fullWidth />
              <ControlledTextField
                control={control}
                name={"credentials.ingramUsername"}
                label={"User email"}
                fullWidth
              />
              <ControlledTextField control={control} name={"credentials.ingramPassword"} label={"Password"} fullWidth />
            </>
          )}
          {(selectedType === AZURE_ACCOUNT_TYPE.CA_EA || selectedType === AZURE_ACCOUNT_TYPE.AZURE_INDIRECT) && (
            <>
              <ControlledTextField control={control} name={"credentials.adDomain"} label={"AD Domain"} fullWidth />
              <ControlledTextField control={control} name={"credentials.appId"} label={"Application ID"} fullWidth />
              <ControlledTextField control={control} name={"credentials.appKey"} label={"Application key"} fullWidth />
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onBack}>Previous</Button>
        {buttonCancel ? <Button onClick={onClose}>Cancel</Button> : ""}
        {(selectedType === AZURE_ACCOUNT_TYPE.CA_EA_CONNECTOR ||
          selectedType === AZURE_ACCOUNT_TYPE.AZURE_INDIRECT_CONNECTOR ||
          selectedType === AZURE_ACCOUNT_TYPE.M365_CONNECTOR) &&
        status !== CA_CONSENT.STATUS.VERIFIED &&
        !isReaderRightSet ? (
          <ButtonConfirm disabled>Save</ButtonConfirm>
        ) : (
          <ButtonConfirm isSubmitting={isSubmitting}>Save</ButtonConfirm>
        )}
      </DialogActions>
    </form>
  );
}

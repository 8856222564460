import { useState } from "react";
import { DataSourceResponseDTO, postCollectorConfig } from "../../../clients/collector.client";
import { CFCard } from "../../../components/layout/CFContainer";
import { useForm } from "react-hook-form";
import { useFeatureToggle } from "../../../hooks/ft.hooks";
import { FEATURES } from "../../../app.config";
import { CollectorConfigResponseDTO, ModuleAWSRequestDTO } from "../../../open-api";
import TextField from "@material-ui/core/TextField";

interface CollectorAwsProps {
  collector: DataSourceResponseDTO;
  onUpdate: () => void;
  collectorConfig?: CollectorConfigResponseDTO | null;
}
export const CollectorAws = ({ collector, collectorConfig, onUpdate, ...props }: CollectorAwsProps) => {
  const [isEditing, setIsEditing] = useState(false);

  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm<ModuleAWSRequestDTO>({
    defaultValues: {
      costExplorerGroupByTagKey: collectorConfig?.aws?.values?.costExplorerGroupByTagKey,
    },
  });

  const useFeature = useFeatureToggle(FEATURES.awsMultipleTags);

  if (!useFeature) {
    return null;
  }

  const onSubmit = (values: ModuleAWSRequestDTO) => {
    return postCollectorConfig(collector.id, {
      aws: values,
    }).then((it) => {
      if (onUpdate) {
        onUpdate();
      }
      setIsEditing(false);
    });
  };

  if (!collectorConfig?.aws) {
    return null;
  }

  const currentGroupByTagKey = collectorConfig.aws.values?.costExplorerGroupByTagKey;

  const AWSConfig = () => (
    <>
      {currentGroupByTagKey ? (
        <>
          AWS Cost explorer usage is grouped using the values associated with the tag key:{" "}
          <strong>{currentGroupByTagKey}</strong>
        </>
      ) : (
        <>No tag configured for grouping of cost explorer usage.</>
      )}
    </>
  );

  const AWSEdit = () => (
    <>
      <TextField
        label={"Enter the tag used to group usage data from the Cost explorer API"}
        variant="outlined"
        fullWidth
        {...register("costExplorerGroupByTagKey")}
        error={
          errors.costExplorerGroupByTagKey?.message !== undefined &&
          errors.costExplorerGroupByTagKey?.message?.length > 0
        }
      />
    </>
  );

  return (
    <CFCard
      header={"AWS Cost explorer configuration"}
      actions={{ edit: !isEditing ? () => setIsEditing(true) : undefined }}
      formActions={
        isEditing
          ? {
              cancel: () => setIsEditing(false),
              handleSubmit: handleSubmit(onSubmit),
            }
          : undefined
      }
    >
      {isEditing ? <AWSEdit /> : <AWSConfig />}
    </CFCard>
  );
};

import { Box, Grid } from "@material-ui/core";
import { InvoiceHeader } from "./components/InvoiceHeader";
import { Skeleton } from "@material-ui/lab";
import { HorizontalDLSkeleton } from "../../components/grid/HorizontalDL";
import { LINE_ITEM_DEFAULTS, useLineItemsForInvoice } from "../../clients/lineitems.client";
import { InvoiceResponseDTO } from "../../open-api";
import LineItemTable, { DIMENSIONS_DEFAULT, LINE_ITEM_TABLE_DIMENSIONS } from "../../components/billing/LineItemTable";
import { CurrencyContext } from "../../hooks/config.hooks";

interface InvoiceProps {
  invoice: InvoiceResponseDTO;
}

export function Invoice({ invoice }: InvoiceProps) {
  const [lineItems, , loading] = useLineItemsForInvoice(invoice.id);

  const dataSourceId = invoice.datasource.id;

  if (!lineItems || loading) {
    return <LoadingSkeleton />;
  }

  const unassigned =
    lineItems.length === 0 ? null : lineItems.find((it) => it.label === LINE_ITEM_DEFAULTS.TYPE.UNMAPPED);
  const rest = lineItems.length === 0 ? [] : lineItems.filter((it) => it.label !== LINE_ITEM_DEFAULTS.TYPE.UNMAPPED);
  rest.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <CurrencyContext.Provider value={invoice.currency}>
      <Box>
        <InvoiceHeader invoice={invoice} />
        {unassigned && (
          <LineItemTable
            dataSourceId={dataSourceId}
            key={unassigned.id}
            titleItem={unassigned}
            lineItems={unassigned.children[0].children}
            dimensions={LINE_ITEM_TABLE_DIMENSIONS}
            valueKeys={["cost"]}
          />
        )}
        {rest.map((it) => (
          <LineItemTable
            dataSourceId={dataSourceId}
            key={it.id}
            dimensions={DIMENSIONS_DEFAULT}
            lineItems={[it]}
            valueKeys={["cost"]}
          />
        ))}
      </Box>
    </CurrencyContext.Provider>
  );
}

function LoadingSkeleton() {
  return (
    <Grid container>
      <Grid item xs={9}>
        <Skeleton height={40} width={150} />
        <Skeleton width={100} />
      </Grid>
      <Grid item xs={3}>
        <HorizontalDLSkeleton expectedItems={2} colSize={6} />
      </Grid>
      <Grid item xs={12} />
      <Grid item xs={12}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Grid>
    </Grid>
  );
}

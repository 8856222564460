/* tslint:disable */
/* eslint-disable */
/**
 * 
 * 
 *
 * 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    CreateWorkspaceUserRequestDTO,
    EditWorkspaceUserRequestDTO,
    UserDTO,
} from '../models';

export interface CreateUserRequest {
    createWorkspaceUserRequestDTO: CreateWorkspaceUserRequestDTO;
}

export interface DeleteUserRequest {
    userId: string;
}

export interface EditUserRequest {
    userId: string;
    editWorkspaceUserRequestDTO: EditWorkspaceUserRequestDTO;
}

/**
 * 
 */
export class UserControllerApi extends runtime.BaseAPI {

    /**
     */
    async createUserRaw(requestParameters: CreateUserRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserDTO>> {
        if (requestParameters.createWorkspaceUserRequestDTO === null || requestParameters.createWorkspaceUserRequestDTO === undefined) {
            throw new runtime.RequiredError('createWorkspaceUserRequestDTO','Required parameter requestParameters.createWorkspaceUserRequestDTO was null or undefined when calling createUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/workspace/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createWorkspaceUserRequestDTO,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async createUser(requestParameters: CreateUserRequest, initOverrides?: RequestInit): Promise<UserDTO> {
        const response = await this.createUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteUserRaw(requestParameters: DeleteUserRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling deleteUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/workspace/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteUser(requestParameters: DeleteUserRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteUserRaw(requestParameters, initOverrides);
    }

    /**
     */
    async editUserRaw(requestParameters: EditUserRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling editUser.');
        }

        if (requestParameters.editWorkspaceUserRequestDTO === null || requestParameters.editWorkspaceUserRequestDTO === undefined) {
            throw new runtime.RequiredError('editWorkspaceUserRequestDTO','Required parameter requestParameters.editWorkspaceUserRequestDTO was null or undefined when calling editUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/workspace/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.editWorkspaceUserRequestDTO,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async editUser(requestParameters: EditUserRequest, initOverrides?: RequestInit): Promise<void> {
        await this.editUserRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getUsersRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<UserDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/workspace/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async getUsers(initOverrides?: RequestInit): Promise<Array<UserDTO>> {
        const response = await this.getUsersRaw(initOverrides);
        return await response.value();
    }

}

import { OrganizationalUnitResponseDTO } from "../open-api";

export function flattenAll(allItems: OrganizationalUnitResponseDTO[]) {
  let flattened = allItems.flatMap((item) => item);
  allItems.forEach((item) => item.children && flattened.push(...flattenAll(item.children)));
  return flattened;
}

export function getOrganizationUnitLabel(
  value: "CUSTOMER" | "INTERNAL" | "RESELLER" | "CONVERT_FROM_CLOUD" | "DISCOUNTS" | string
) {
  switch (value) {
    case "CUSTOMER":
      return ["Customer", "Customers"];
    case "INTERNAL":
      return ["My Organization", "My Organizations"];
    case "CONVERT_FROM_CLOUD":
      return ["Convert from Cloud", "Convert from Cloud"];
    case "DISCOUNTS":
      return ["Discounts table", "Discounts table"];
    case "RESELLER":
      return ["Reseller", "Resellers"];
    default:
      return ["Unknown", "Unknown"];
  }
}

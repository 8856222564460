/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 * @interface AWSCredentialsDTO
 */
export interface AWSCredentialsDTO extends CloudCredentialsDTO {
  /**
   *
   * @type {string}
   * @memberof AWSCredentialsDTO
   */
  type: AWSCredentialsDTOTypeEnum;
  /**
   *
   * @type {string}
   * @memberof AWSCredentialsDTO
   */
  accessKey?: string;
  /**
   *
   * @type {string}
   * @memberof AWSCredentialsDTO
   */
  secretKey?: string;
  /**
   *
   * @type {string}
   * @memberof AWSCredentialsDTO
   */
  roleToAssumeExternalId?: string;
  /**
   *
   * @type {string}
   * @memberof AWSCredentialsDTO
   */
  roleToAssumeArn?: string;
  /**
   *
   * @type {string}
   * @memberof AWSCredentialsDTO
   */
  accessType: AWSCredentialsDTOAccessTypeEnum;
  /**
   *
   * @type {string}
   * @memberof AWSCredentialsDTO
   */
  groupByTagKey?: string;
  /**
   *
   * @type {BucketCredentials}
   * @memberof AWSCredentialsDTO
   */
  remoteBucket?: BucketCredentials;
}

/**
 * @export
 * @enum {string}
 */

export const AWSCredentialsDTOTypeEnum = {
  AWS: "AWS",
} as const;
export type AWSCredentialsDTOTypeEnum =
  typeof AWSCredentialsDTOTypeEnum[keyof typeof AWSCredentialsDTOTypeEnum];
/**
 * @export
 * @enum {string}
 */

export const AWSCredentialsDTOAccessTypeEnum = {
  IAM_ROLE: "IAM_ROLE",
  IAM_USER: "IAM_USER",
} as const;
export type AWSCredentialsDTOAccessTypeEnum =
  typeof AWSCredentialsDTOAccessTypeEnum[keyof typeof AWSCredentialsDTOAccessTypeEnum];

/**
 *
 * @export
 * @interface AWSCredentialsDTOAllOf
 */
export interface AWSCredentialsDTOAllOf {
  /**
   *
   * @type {string}
   * @memberof AWSCredentialsDTOAllOf
   */
  type?: AWSCredentialsDTOAllOfTypeEnum;
  /**
   *
   * @type {string}
   * @memberof AWSCredentialsDTOAllOf
   */
  accessKey?: string;
  /**
   *
   * @type {string}
   * @memberof AWSCredentialsDTOAllOf
   */
  secretKey?: string;
  /**
   *
   * @type {string}
   * @memberof AWSCredentialsDTOAllOf
   */
  roleToAssumeExternalId?: string;
  /**
   *
   * @type {string}
   * @memberof AWSCredentialsDTOAllOf
   */
  roleToAssumeArn?: string;
  /**
   *
   * @type {string}
   * @memberof AWSCredentialsDTOAllOf
   */
  accessType?: AWSCredentialsDTOAllOfAccessTypeEnum;
  /**
   *
   * @type {string}
   * @memberof AWSCredentialsDTOAllOf
   */
  groupByTagKey?: string;
  /**
   *
   * @type {BucketCredentials}
   * @memberof AWSCredentialsDTOAllOf
   */
  remoteBucket?: BucketCredentials;
}

/**
 * @export
 * @enum {string}
 */

export const AWSCredentialsDTOAllOfTypeEnum = {
  AWS: "AWS",
} as const;
export type AWSCredentialsDTOAllOfTypeEnum =
  typeof AWSCredentialsDTOAllOfTypeEnum[keyof typeof AWSCredentialsDTOAllOfTypeEnum];
/**
 * @export
 * @enum {string}
 */

export const AWSCredentialsDTOAllOfAccessTypeEnum = {
  IAM_ROLE: "IAM_ROLE",
  IAM_USER: "IAM_USER",
} as const;
export type AWSCredentialsDTOAllOfAccessTypeEnum =
  typeof AWSCredentialsDTOAllOfAccessTypeEnum[keyof typeof AWSCredentialsDTOAllOfAccessTypeEnum];

/**
 *
 * @export
 * @interface ApiError
 */
export interface ApiError {
  /**
   * Http status code enum
   * @type {string}
   * @memberof ApiError
   */
  status?: ApiErrorStatusEnum;
  /**
   * Message explaining why the request was not accepted
   * @type {string}
   * @memberof ApiError
   */
  message?: string;
  /**
   * Validation errors if they exist
   * @type {{ [key: string]: string; }}
   * @memberof ApiError
   */
  errors?: { [key: string]: string };
}

/**
 * @export
 * @enum {string}
 */

export const ApiErrorStatusEnum = {} as const;
export type ApiErrorStatusEnum =
  typeof ApiErrorStatusEnum[keyof typeof ApiErrorStatusEnum];

/**
 *
 * @export
 * @interface AttributeFilterDTO
 */
export interface AttributeFilterDTO {
  /**
   *
   * @type {string}
   * @memberof AttributeFilterDTO
   */
  attributeId?: string;
  /**
   *
   * @type {string}
   * @memberof AttributeFilterDTO
   */
  attributeDisplay?: string;
  /**
   *
   * @type {string}
   * @memberof AttributeFilterDTO
   */
  attributeType?: AttributeFilterDTOAttributeTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof AttributeFilterDTO
   */
  includeUndefined?: boolean;
  /**
   *
   * @type {Array<AttributeFilterValueDTO>}
   * @memberof AttributeFilterDTO
   */
  values?: Array<AttributeFilterValueDTO>;
}

/**
 * @export
 * @enum {string}
 */

export const AttributeFilterDTOAttributeTypeEnum = {
  AZURE_CUSTOMER: "AZURE_CUSTOMER",
  AZURE_SUBSCRIPTION: "AZURE_SUBSCRIPTION",
  AZURE_RESOURCE_GROUP: "AZURE_RESOURCE_GROUP",
  AZURE_MANAGEMENT_GROUP: "AZURE_MANAGEMENT_GROUP",
  AZURE_TAG: "AZURE_TAG",
  AZURE_LOCATION: "AZURE_LOCATION",
  AZURE_REGION: "AZURE_REGION",
  AZURE_INSTANCE: "AZURE_INSTANCE",
  MS_RESELLER: "MS_RESELLER",
  M365_LICENSE: "M365_LICENSE",
  AWS_TAG: "AWS_TAG",
  AWS_ACCOUNT_ALIAS: "AWS_ACCOUNT_ALIAS",
  AWS_LOCATION: "AWS_LOCATION",
  AWS_INSTANCE: "AWS_INSTANCE",
  OCI_TENANT: "OCI_TENANT",
  OCI_COMPARTMENT: "OCI_COMPARTMENT",
  OCI_REGION: "OCI_REGION",
  OCI_TAG: "OCI_TAG",
  GOOGLE_PROJECT: "GOOGLE_PROJECT",
  GOOGLE_BILLING_ACCOUNT_ID: "GOOGLE_BILLING_ACCOUNT_ID",
  GOOGLE_LABEL: "GOOGLE_LABEL",
} as const;
export type AttributeFilterDTOAttributeTypeEnum =
  typeof AttributeFilterDTOAttributeTypeEnum[keyof typeof AttributeFilterDTOAttributeTypeEnum];

/**
 *
 * @export
 * @interface AttributeFilterValueDTO
 */
export interface AttributeFilterValueDTO {
  /**
   *
   * @type {string}
   * @memberof AttributeFilterValueDTO
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof AttributeFilterValueDTO
   */
  display?: string;
  /**
   *
   * @type {string}
   * @memberof AttributeFilterValueDTO
   */
  state?: AttributeFilterValueDTOStateEnum;
  /**
   *
   * @type {string} An ISO date string
   * @memberof AttributeFilterValueDTO
   */
  hideSince?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof AttributeFilterValueDTO
   */
  dependsOn?: Array<string>;
}

/**
 * @export
 * @enum {string}
 */

export const AttributeFilterValueDTOStateEnum = {
  NEW: "NEW",
  INCLUDE: "INCLUDE",
  HIDE: "HIDE",
  DELETE: "DELETE",
} as const;
export type AttributeFilterValueDTOStateEnum =
  typeof AttributeFilterValueDTOStateEnum[keyof typeof AttributeFilterValueDTOStateEnum];

/**
 *
 * @export
 * @interface AttributeHierarchyResponseDTO
 */
export interface AttributeHierarchyResponseDTO {
  /**
   *
   * @type {string}
   * @memberof AttributeHierarchyResponseDTO
   */
  collectorId: string;
  /**
   *
   * @type {AttributeTypeDTO}
   * @memberof AttributeHierarchyResponseDTO
   */
  root?: AttributeTypeDTO;
  /**
   *
   * @type {Array<AttributeTypeDTO>}
   * @memberof AttributeHierarchyResponseDTO
   */
  detail?: Array<AttributeTypeDTO>;
}
/**
 *
 * @export
 * @interface AttributeResponseDTO
 */
export interface AttributeResponseDTO {
  /**
   *
   * @type {AttributeTypeDTO}
   * @memberof AttributeResponseDTO
   */
  type: AttributeTypeDTO;
  /**
   *
   * @type {{ [key: string]: AttributeValueDTO; }}
   * @memberof AttributeResponseDTO
   */
  values: { [key: string]: AttributeValueDTO };
}
/**
 *
 * @export
 * @interface AttributeTypeDTO
 */
export interface AttributeTypeDTO {
  /**
   *
   * @type {string}
   * @memberof AttributeTypeDTO
   */
  uuid?: string;
  /**
   *
   * @type {string}
   * @memberof AttributeTypeDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AttributeTypeDTO
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof AttributeTypeDTO
   */
  param?: string;
  /**
   *
   * @type {string}
   * @memberof AttributeTypeDTO
   */
  displayValue: string;
  /**
   *
   * @type {string}
   * @memberof AttributeTypeDTO
   */
  attributeDisplayValue?: string;
  /**
   *
   * @type {string}
   * @memberof AttributeTypeDTO
   */
  paramDisplayValue?: string;
  /**
   *
   * @type {string}
   * @memberof AttributeTypeDTO
   */
  category: AttributeTypeDTOCategoryEnum;
  /**
   *
   * @type {string}
   * @memberof AttributeTypeDTO
   */
  collectorId?: string;
  /**
   *
   * @type {string}
   * @memberof AttributeTypeDTO
   */
  collectorName?: string;
  /**
   *
   * @type {string}
   * @memberof AttributeTypeDTO
   */
  displayState?: AttributeTypeDTODisplayStateEnum;
}

/**
 * @export
 * @enum {string}
 */

export const AttributeTypeDTOCategoryEnum = {
  CF_ATTRIBUTE: "CF_ATTRIBUTE",
  BILLING_HIERARCHY_ATTRIBUTE: "BILLING_HIERARCHY_ATTRIBUTE",
  DATA_SOURCE_ATTRIBUTE: "DATA_SOURCE_ATTRIBUTE",
} as const;
export type AttributeTypeDTOCategoryEnum =
  typeof AttributeTypeDTOCategoryEnum[keyof typeof AttributeTypeDTOCategoryEnum];
/**
 * @export
 * @enum {string}
 */

export const AttributeTypeDTODisplayStateEnum = {
  NEW: "NEW",
  INCLUDE: "INCLUDE",
  HIDE: "HIDE",
  DELETE: "DELETE",
} as const;
export type AttributeTypeDTODisplayStateEnum =
  typeof AttributeTypeDTODisplayStateEnum[keyof typeof AttributeTypeDTODisplayStateEnum];

/**
 *
 * @export
 * @interface AttributeValueDTO
 */
export interface AttributeValueDTO {
  /**
   *
   * @type {string}
   * @memberof AttributeValueDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AttributeValueDTO
   */
  externalId: string;
  /**
   *
   * @type {string}
   * @memberof AttributeValueDTO
   */
  displayValue: string;
  /**
   *
   * @type {object}
   * @memberof AttributeValueDTO
   */
  metadata?: object;
}
/**
 *
 * @export
 * @interface AzureAdAuthenticationResultDTO
 */
export interface AzureAdAuthenticationResultDTO {
  /**
   *
   * @type {string}
   * @memberof AzureAdAuthenticationResultDTO
   */
  inputEmail: string;
  /**
   *
   * @type {string}
   * @memberof AzureAdAuthenticationResultDTO
   */
  authenticatedEmail: string;
  /**
   *
   * @type {string}
   * @memberof AzureAdAuthenticationResultDTO
   */
  name: string;
  /**
   *
   * @type {Array<string>}
   * @memberof AzureAdAuthenticationResultDTO
   */
  groups: Array<string>;
}
/**
 *
 * @export
 * @interface AzureAdRequestDTO
 */
export interface AzureAdRequestDTO {
  /**
   *
   * @type {string}
   * @memberof AzureAdRequestDTO
   */
  email: string;
}
/**
 *
 * @export
 * @interface AzureSsoConfigRequestDTO
 */
export interface AzureSsoConfigRequestDTO {
  /**
   *
   * @type {string}
   * @memberof AzureSsoConfigRequestDTO
   */
  adDomain: string;
  /**
   *
   * @type {Array<string>}
   * @memberof AzureSsoConfigRequestDTO
   */
  domains: Array<string>;
  /**
   *
   * @type {string}
   * @memberof AzureSsoConfigRequestDTO
   */
  applicationId: string;
  /**
   *
   * @type {string}
   * @memberof AzureSsoConfigRequestDTO
   */
  groupId: string;
  /**
   *
   * @type {boolean}
   * @memberof AzureSsoConfigRequestDTO
   */
  ssoEnabled: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AzureSsoConfigRequestDTO
   */
  ssoForced: boolean;
}
/**
 *
 * @export
 * @interface AzureSsoConfigResponseDTO
 */
export interface AzureSsoConfigResponseDTO {
  /**
   *
   * @type {string}
   * @memberof AzureSsoConfigResponseDTO
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof AzureSsoConfigResponseDTO
   */
  adDomain: string;
  /**
   *
   * @type {string}
   * @memberof AzureSsoConfigResponseDTO
   */
  applicationId: string;
  /**
   *
   * @type {string}
   * @memberof AzureSsoConfigResponseDTO
   */
  groupId: string;
  /**
   *
   * @type {string}
   * @memberof AzureSsoConfigResponseDTO
   */
  workspaceId: string;
  /**
   *
   * @type {boolean}
   * @memberof AzureSsoConfigResponseDTO
   */
  ssoEnabled: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AzureSsoConfigResponseDTO
   */
  ssoForced: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof AzureSsoConfigResponseDTO
   */
  domains: Array<string>;
}
/**
 *
 * @export
 * @interface BatchProjectionAttributeValueDTO
 */
export interface BatchProjectionAttributeValueDTO {
  /**
   *
   * @type {string}
   * @memberof BatchProjectionAttributeValueDTO
   */
  collectorId: string;
  /**
   *
   * @type {string}
   * @memberof BatchProjectionAttributeValueDTO
   */
  rootAttributeValue: string;
  /**
   *
   * @type {string}
   * @memberof BatchProjectionAttributeValueDTO
   */
  detailAttributeValue?: string;
}
/**
 *
 * @export
 * @interface BatchProjectionCollectorAttributesDTO
 */
export interface BatchProjectionCollectorAttributesDTO {
  /**
   *
   * @type {BatchProjectionCollectorDTO}
   * @memberof BatchProjectionCollectorAttributesDTO
   */
  collector: BatchProjectionCollectorDTO;
  /**
   *
   * @type {AttributeTypeDTO}
   * @memberof BatchProjectionCollectorAttributesDTO
   */
  rootAttribute: AttributeTypeDTO;
  /**
   *
   * @type {RootFilterDTO}
   * @memberof BatchProjectionCollectorAttributesDTO
   */
  rootFilter?: RootFilterDTO;
  /**
   *
   * @type {AttributeTypeDTO}
   * @memberof BatchProjectionCollectorAttributesDTO
   */
  detailAttribute?: AttributeTypeDTO;
  /**
   *
   * @type {Array<AttributeValueDTO>}
   * @memberof BatchProjectionCollectorAttributesDTO
   */
  attributes: Array<AttributeValueDTO>;
}
/**
 *
 * @export
 * @interface BatchProjectionCollectorDTO
 */
export interface BatchProjectionCollectorDTO {
  /**
   *
   * @type {string}
   * @memberof BatchProjectionCollectorDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof BatchProjectionCollectorDTO
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof BatchProjectionCollectorDTO
   */
  type: BatchProjectionCollectorDTOTypeEnum;
}

/**
 * @export
 * @enum {string}
 */

export const BatchProjectionCollectorDTOTypeEnum = {
  AWS: "AWS",
  ORACLE: "ORACLE",
  SERVICE_PROVIDER: "SERVICE_PROVIDER",
  CA: "CA",
  CSP: "CSP",
  INGRAMCA: "INGRAMCA",
  GOOGLE: "GOOGLE",
  UPLOAD: "UPLOAD",
  AZURE: "AZURE",
} as const;
export type BatchProjectionCollectorDTOTypeEnum =
  typeof BatchProjectionCollectorDTOTypeEnum[keyof typeof BatchProjectionCollectorDTOTypeEnum];

/**
 *
 * @export
 * @interface BatchProjectionOrganizationAttributeValueResponseDTO
 */
export interface BatchProjectionOrganizationAttributeValueResponseDTO {
  /**
   *
   * @type {string}
   * @memberof BatchProjectionOrganizationAttributeValueResponseDTO
   */
  collectorId: string;
  /**
   *
   * @type {Array<AttributeValueDTO>}
   * @memberof BatchProjectionOrganizationAttributeValueResponseDTO
   */
  values: Array<AttributeValueDTO>;
}
/**
 *
 * @export
 * @interface BatchProjectionOrganizationDTO
 */
export interface BatchProjectionOrganizationDTO {
  /**
   *
   * @type {string}
   * @memberof BatchProjectionOrganizationDTO
   */
  type: string;
}
/**
 *
 * @export
 * @interface BatchProjectionOrganizationResponseDTO
 */
export interface BatchProjectionOrganizationResponseDTO {
  /**
   *
   * @type {string}
   * @memberof BatchProjectionOrganizationResponseDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof BatchProjectionOrganizationResponseDTO
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof BatchProjectionOrganizationResponseDTO
   */
  type: BatchProjectionOrganizationResponseDTOTypeEnum;
  /**
   *
   * @type {Array<BatchProjectionOrganizationAttributeValueResponseDTO>}
   * @memberof BatchProjectionOrganizationResponseDTO
   */
  attributeValues: Array<BatchProjectionOrganizationAttributeValueResponseDTO>;
}

/**
 * @export
 * @enum {string}
 */

export const BatchProjectionOrganizationResponseDTOTypeEnum = {
  CUSTOMER: "CUSTOMER",
  INTERNAL: "INTERNAL",
  RESELLER: "RESELLER",
} as const;
export type BatchProjectionOrganizationResponseDTOTypeEnum =
  typeof BatchProjectionOrganizationResponseDTOTypeEnum[keyof typeof BatchProjectionOrganizationResponseDTOTypeEnum];

/**
 *
 * @export
 * @interface BatchProjectionResponseDTO
 */
export interface BatchProjectionResponseDTO {
  /**
   *
   * @type {Array<BatchProjectionCollectorAttributesDTO>}
   * @memberof BatchProjectionResponseDTO
   */
  collectors: Array<BatchProjectionCollectorAttributesDTO>;
  /**
   *
   * @type {Array<BatchProjectionOrganizationResponseDTO>}
   * @memberof BatchProjectionResponseDTO
   */
  organizations: Array<BatchProjectionOrganizationResponseDTO>;
}
/**
 *
 * @export
 * @interface BillingPeriod
 */
export interface BillingPeriod {
  /**
   *
   * @type {string} An ISO date string
   * @memberof BillingPeriod
   */
  from: string;
  /**
   *
   * @type {string} An ISO date string
   * @memberof BillingPeriod
   */
  to: string;
  /**
   *
   * @type {string}
   * @memberof BillingPeriod
   */
  anchorDate: string;
  /**
   *
   * @type {string}
   * @memberof BillingPeriod
   */
  anchorDateDisplay: string;
}
/**
 *
 * @export
 * @interface BootstrapRequestDTO
 */
export interface BootstrapRequestDTO {
  /**
   *
   * @type {boolean}
   * @memberof BootstrapRequestDTO
   */
  authenticated: boolean;
  /**
   *
   * @type {UserDTO}
   * @memberof BootstrapRequestDTO
   */
  user?: UserDTO;
  /**
   *
   * @type {WorkspaceDTO}
   * @memberof BootstrapRequestDTO
   */
  workspace?: WorkspaceDTO;
  /**
   *
   * @type {boolean}
   * @memberof BootstrapRequestDTO
   */
  isAssumed?: boolean;
}
/**
 *
 * @export
 * @interface BucketCredentials
 */
export interface BucketCredentials {
  /**
   *
   * @type {string}
   * @memberof BucketCredentials
   */
  bucketName?: string;
  /**
   *
   * @type {string}
   * @memberof BucketCredentials
   */
  region?: string;
}
/**
 *
 * @export
 * @interface BudgetAlertEmailNotificationResponseDTO
 */
export interface BudgetAlertEmailNotificationResponseDTO {
  /**
   *
   * @type {string}
   * @memberof BudgetAlertEmailNotificationResponseDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof BudgetAlertEmailNotificationResponseDTO
   */
  recipient: string;
}
/**
 *
 * @export
 * @interface BudgetAlertNotificationResponseDTO
 */
export interface BudgetAlertNotificationResponseDTO {
  /**
   *
   * @type {Array<BudgetAlertEmailNotificationResponseDTO>}
   * @memberof BudgetAlertNotificationResponseDTO
   */
  email?: Array<BudgetAlertEmailNotificationResponseDTO>;
}
/**
 *
 * @export
 * @interface BudgetAlertResponseDTO
 */
export interface BudgetAlertResponseDTO {
  /**
   *
   * @type {string}
   * @memberof BudgetAlertResponseDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof BudgetAlertResponseDTO
   */
  alertType: string;
  /**
   *
   * @type {string}
   * @memberof BudgetAlertResponseDTO
   */
  thresholdType: string;
  /**
   *
   * @type {number}
   * @memberof BudgetAlertResponseDTO
   */
  thresholdValue: number;
  /**
   *
   * @type {BudgetAlertNotificationResponseDTO}
   * @memberof BudgetAlertResponseDTO
   */
  notifications: BudgetAlertNotificationResponseDTO;
  /**
   *
   * @type {RecentStatusResponseDTO}
   * @memberof BudgetAlertResponseDTO
   */
  recentStatus?: RecentStatusResponseDTO;
}
/**
 *
 * @export
 * @interface BudgetResponseDTO
 */
export interface BudgetResponseDTO {
  /**
   *
   * @type {string}
   * @memberof BudgetResponseDTO
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof BudgetResponseDTO
   */
  value: number;
  /**
   *
   * @type {string}
   * @memberof BudgetResponseDTO
   */
  currency: string;
  /**
   *
   * @type {string}
   * @memberof BudgetResponseDTO
   */
  period: string;
  /**
   *
   * @type {string}
   * @memberof BudgetResponseDTO
   */
  budgetType: string;
  /**
   *
   * @type {Array<BudgetAlertResponseDTO>}
   * @memberof BudgetResponseDTO
   */
  alerts?: Array<BudgetAlertResponseDTO>;
}
/**
 *
 * @export
 * @interface CAConnectorCredentialsDTO
 */
export interface CAConnectorCredentialsDTO extends CloudCredentialsDTO {
  /**
   *
   * @type {string}
   * @memberof CAConnectorCredentialsDTO
   */
  type: CAConnectorCredentialsDTOTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CAConnectorCredentialsDTO
   */
  adDomain: string;
}

/**
 * @export
 * @enum {string}
 */

export const CAConnectorCredentialsDTOTypeEnum = {
  CA_CONNECTOR: "CA_CONNECTOR",
} as const;
export type CAConnectorCredentialsDTOTypeEnum =
  typeof CAConnectorCredentialsDTOTypeEnum[keyof typeof CAConnectorCredentialsDTOTypeEnum];

/**
 *
 * @export
 * @interface CAConnectorCredentialsDTOAllOf
 */
export interface CAConnectorCredentialsDTOAllOf {
  /**
   *
   * @type {string}
   * @memberof CAConnectorCredentialsDTOAllOf
   */
  type?: CAConnectorCredentialsDTOAllOfTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CAConnectorCredentialsDTOAllOf
   */
  adDomain?: string;
}

/**
 * @export
 * @enum {string}
 */

export const CAConnectorCredentialsDTOAllOfTypeEnum = {
  CA_CONNECTOR: "CA_CONNECTOR",
} as const;
export type CAConnectorCredentialsDTOAllOfTypeEnum =
  typeof CAConnectorCredentialsDTOAllOfTypeEnum[keyof typeof CAConnectorCredentialsDTOAllOfTypeEnum];

/**
 *
 * @export
 * @interface CACredentialsDTO
 */
export interface CACredentialsDTO extends CloudCredentialsDTO {
  /**
   *
   * @type {string}
   * @memberof CACredentialsDTO
   */
  type: CACredentialsDTOTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CACredentialsDTO
   */
  appId: string;
  /**
   *
   * @type {string}
   * @memberof CACredentialsDTO
   */
  appKey: string;
  /**
   *
   * @type {string}
   * @memberof CACredentialsDTO
   */
  adDomain: string;
}

/**
 * @export
 * @enum {string}
 */

export const CACredentialsDTOTypeEnum = {
  CA: "CA",
} as const;
export type CACredentialsDTOTypeEnum =
  typeof CACredentialsDTOTypeEnum[keyof typeof CACredentialsDTOTypeEnum];

/**
 *
 * @export
 * @interface CACredentialsDTOAllOf
 */
export interface CACredentialsDTOAllOf {
  /**
   *
   * @type {string}
   * @memberof CACredentialsDTOAllOf
   */
  type?: CACredentialsDTOAllOfTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CACredentialsDTOAllOf
   */
  appId?: string;
  /**
   *
   * @type {string}
   * @memberof CACredentialsDTOAllOf
   */
  appKey?: string;
  /**
   *
   * @type {string}
   * @memberof CACredentialsDTOAllOf
   */
  adDomain?: string;
}

/**
 * @export
 * @enum {string}
 */

export const CACredentialsDTOAllOfTypeEnum = {
  CA: "CA",
} as const;
export type CACredentialsDTOAllOfTypeEnum =
  typeof CACredentialsDTOAllOfTypeEnum[keyof typeof CACredentialsDTOAllOfTypeEnum];

/**
 *
 * @export
 * @interface CSPCredentialsDTO
 */
export interface CSPCredentialsDTO extends CloudCredentialsDTO {
  /**
   *
   * @type {string}
   * @memberof CSPCredentialsDTO
   */
  type: CSPCredentialsDTOTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CSPCredentialsDTO
   */
  adDomain: string;
  /**
   *
   * @type {string}
   * @memberof CSPCredentialsDTO
   */
  appId: string;
  /**
   *
   * @type {string}
   * @memberof CSPCredentialsDTO
   */
  appKey: string;
  /**
   *
   * @type {string}
   * @memberof CSPCredentialsDTO
   */
  refreshToken: string;
}

/**
 * @export
 * @enum {string}
 */

export const CSPCredentialsDTOTypeEnum = {
  CSP: "CSP",
} as const;
export type CSPCredentialsDTOTypeEnum =
  typeof CSPCredentialsDTOTypeEnum[keyof typeof CSPCredentialsDTOTypeEnum];

/**
 *
 * @export
 * @interface CSPCredentialsDTOAllOf
 */
export interface CSPCredentialsDTOAllOf {
  /**
   *
   * @type {string}
   * @memberof CSPCredentialsDTOAllOf
   */
  type?: CSPCredentialsDTOAllOfTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CSPCredentialsDTOAllOf
   */
  adDomain?: string;
  /**
   *
   * @type {string}
   * @memberof CSPCredentialsDTOAllOf
   */
  appId?: string;
  /**
   *
   * @type {string}
   * @memberof CSPCredentialsDTOAllOf
   */
  appKey?: string;
  /**
   *
   * @type {string}
   * @memberof CSPCredentialsDTOAllOf
   */
  refreshToken?: string;
}

/**
 * @export
 * @enum {string}
 */

export const CSPCredentialsDTOAllOfTypeEnum = {
  CSP: "CSP",
} as const;
export type CSPCredentialsDTOAllOfTypeEnum =
  typeof CSPCredentialsDTOAllOfTypeEnum[keyof typeof CSPCredentialsDTOAllOfTypeEnum];

/**
 *
 * @export
 * @interface CloudCredentialsDTO
 */
export interface CloudCredentialsDTO {
  /**
   *
   * @type {string}
   * @memberof CloudCredentialsDTO
   */
  type: string;
}
/**
 *
 * @export
 * @interface CollectorAws
 */
export interface CollectorAws {
  /**
   *
   * @type {string}
   * @memberof CollectorAws
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof CollectorAws
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof CollectorAws
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CollectorAws
   */
  accountId: string;
  /**
   *
   * @type {CollectorAwsCredentials}
   * @memberof CollectorAws
   */
  credentials: CollectorAwsCredentials;
  /**
   *
   * @type {string} An ISO date string
   * @memberof CollectorAws
   */
  updatedAt?: string;
  /**
   *
   * @type {string}
   * @memberof CollectorAws
   */
  status: string;
  /**
   *
   * @type {boolean}
   * @memberof CollectorAws
   */
  canDisable: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CollectorAws
   */
  owned: boolean;
  /**
   *
   * @type {string}
   * @memberof CollectorAws
   */
  priceAdjustmentsSet: CollectorAwsPriceAdjustmentsSetEnum;
  /**
   *
   * @type {boolean}
   * @memberof CollectorAws
   */
  isEnabled: boolean;
}

/**
 * @export
 * @enum {string}
 */

export const CollectorAwsPriceAdjustmentsSetEnum = {
  SET: "SET",
  NOT_SET: "NOT_SET",
  NOT_APPLICABLE: "NOT_APPLICABLE",
} as const;
export type CollectorAwsPriceAdjustmentsSetEnum =
  typeof CollectorAwsPriceAdjustmentsSetEnum[keyof typeof CollectorAwsPriceAdjustmentsSetEnum];

/**
 *
 * @export
 * @interface CollectorAwsCredentials
 */
export interface CollectorAwsCredentials {
  /**
   *
   * @type {string}
   * @memberof CollectorAwsCredentials
   */
  accessKey: string;
  /**
   *
   * @type {string}
   * @memberof CollectorAwsCredentials
   */
  secretKey: string;
  /**
   *
   * @type {string}
   * @memberof CollectorAwsCredentials
   */
  groupByTagKey?: string;
}
/**
 *
 * @export
 * @interface CollectorAwsCredentialsInput
 */
export interface CollectorAwsCredentialsInput {
  /**
   *
   * @type {string}
   * @memberof CollectorAwsCredentialsInput
   */
  accessKey?: string;
  /**
   *
   * @type {string}
   * @memberof CollectorAwsCredentialsInput
   */
  secretKey?: string;
  /**
   *
   * @type {string}
   * @memberof CollectorAwsCredentialsInput
   */
  roleToAssumeExternalId?: string;
  /**
   *
   * @type {string}
   * @memberof CollectorAwsCredentialsInput
   */
  roleToAssumeArn?: string;
  /**
   *
   * @type {string}
   * @memberof CollectorAwsCredentialsInput
   */
  accessType?: string;
  /**
   *
   * @type {string}
   * @memberof CollectorAwsCredentialsInput
   */
  groupByTagKey?: string;
  /**
   *
   * @type {string}
   * @memberof CollectorAwsCredentialsInput
   */
  bucketName?: string;
  /**
   *
   * @type {string}
   * @memberof CollectorAwsCredentialsInput
   */
  bucketRegion?: string;
}
/**
 *
 * @export
 * @interface CollectorAwsInput
 */
export interface CollectorAwsInput {
  /**
   *
   * @type {string}
   * @memberof CollectorAwsInput
   */
  name?: string;
  /**
   *
   * @type {CollectorAwsCredentialsInput}
   * @memberof CollectorAwsInput
   */
  credentials?: CollectorAwsCredentialsInput;
}
/**
 *
 * @export
 * @interface CollectorAzure
 */
export interface CollectorAzure {
  /**
   *
   * @type {string}
   * @memberof CollectorAzure
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof CollectorAzure
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof CollectorAzure
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CollectorAzure
   */
  accountId: string;
  /**
   *
   * @type {CollectorAzureCredentials}
   * @memberof CollectorAzure
   */
  credentials: CollectorAzureCredentials;
  /**
   *
   * @type {string} An ISO date string
   * @memberof CollectorAzure
   */
  updatedAt?: string;
  /**
   *
   * @type {string}
   * @memberof CollectorAzure
   */
  status: string;
  /**
   *
   * @type {boolean}
   * @memberof CollectorAzure
   */
  canDisable: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CollectorAzure
   */
  owned: boolean;
  /**
   *
   * @type {string}
   * @memberof CollectorAzure
   */
  priceAdjustmentsSet: CollectorAzurePriceAdjustmentsSetEnum;
  /**
   *
   * @type {boolean}
   * @memberof CollectorAzure
   */
  isEnabled: boolean;
}

/**
 * @export
 * @enum {string}
 */

export const CollectorAzurePriceAdjustmentsSetEnum = {
  SET: "SET",
  NOT_SET: "NOT_SET",
  NOT_APPLICABLE: "NOT_APPLICABLE",
} as const;
export type CollectorAzurePriceAdjustmentsSetEnum =
  typeof CollectorAzurePriceAdjustmentsSetEnum[keyof typeof CollectorAzurePriceAdjustmentsSetEnum];

/**
 *
 * @export
 * @interface CollectorAzureCredentials
 */
export interface CollectorAzureCredentials {
  /**
   *
   * @type {string}
   * @memberof CollectorAzureCredentials
   */
  accountType: string;
  /**
   *
   * @type {string}
   * @memberof CollectorAzureCredentials
   */
  adDomain: string;
  /**
   *
   * @type {string}
   * @memberof CollectorAzureCredentials
   */
  appId?: string;
  /**
   *
   * @type {string}
   * @memberof CollectorAzureCredentials
   */
  appKey?: string;
  /**
   *
   * @type {string}
   * @memberof CollectorAzureCredentials
   */
  enrollmentNumber?: string;
  /**
   *
   * @type {string}
   * @memberof CollectorAzureCredentials
   */
  enrollmentToken?: string;
  /**
   *
   * @type {string}
   * @memberof CollectorAzureCredentials
   */
  refreshToken?: string;
  /**
   *
   * @type {string}
   * @memberof CollectorAzureCredentials
   */
  ingramMarketplace?: string;
  /**
   *
   * @type {string}
   * @memberof CollectorAzureCredentials
   */
  ingramApiKey?: string;
  /**
   *
   * @type {string}
   * @memberof CollectorAzureCredentials
   */
  ingramUsername?: string;
  /**
   *
   * @type {string}
   * @memberof CollectorAzureCredentials
   */
  ingramPassword?: string;
}
/**
 *
 * @export
 * @interface CollectorAzureCredentialsInput
 */
export interface CollectorAzureCredentialsInput {
  /**
   *
   * @type {string}
   * @memberof CollectorAzureCredentialsInput
   */
  accountType?: string;
  /**
   *
   * @type {string}
   * @memberof CollectorAzureCredentialsInput
   */
  adDomain?: string;
  /**
   *
   * @type {string}
   * @memberof CollectorAzureCredentialsInput
   */
  appId?: string;
  /**
   *
   * @type {string}
   * @memberof CollectorAzureCredentialsInput
   */
  appKey?: string;
  /**
   *
   * @type {string}
   * @memberof CollectorAzureCredentialsInput
   */
  enrollmentNumber?: string;
  /**
   *
   * @type {string}
   * @memberof CollectorAzureCredentialsInput
   */
  enrollmentToken?: string;
  /**
   *
   * @type {string}
   * @memberof CollectorAzureCredentialsInput
   */
  refreshToken?: string;
  /**
   *
   * @type {string}
   * @memberof CollectorAzureCredentialsInput
   */
  ingramMarketplace?: string;
  /**
   *
   * @type {string}
   * @memberof CollectorAzureCredentialsInput
   */
  ingramApiKey?: string;
  /**
   *
   * @type {string}
   * @memberof CollectorAzureCredentialsInput
   */
  ingramUsername?: string;
  /**
   *
   * @type {string}
   * @memberof CollectorAzureCredentialsInput
   */
  ingramPassword?: string;
}
/**
 *
 * @export
 * @interface CollectorAzureInput
 */
export interface CollectorAzureInput {
  /**
   *
   * @type {string}
   * @memberof CollectorAzureInput
   */
  name?: string;
  /**
   *
   * @type {CollectorAzureCredentialsInput}
   * @memberof CollectorAzureInput
   */
  credentials?: CollectorAzureCredentialsInput;
  /**
   *
   * @type {CollectorConfigRequestDTO}
   * @memberof CollectorAzureInput
   */
  config?: CollectorConfigRequestDTO;
}
/**
 *
 * @export
 * @interface CollectorConfigRequestDTO
 */
export interface CollectorConfigRequestDTO {
  /**
   *
   * @type {ModuleHierarchyRequestDTO}
   * @memberof CollectorConfigRequestDTO
   */
  hierarchy?: ModuleHierarchyRequestDTO;
  /**
   *
   * @type {ModuleAWSRequestDTO}
   * @memberof CollectorConfigRequestDTO
   */
  aws?: ModuleAWSRequestDTO;
  /**
   *
   * @type {ModuleGenericRequestDTO}
   * @memberof CollectorConfigRequestDTO
   */
  generic?: ModuleGenericRequestDTO;
  /**
   *
   * @type {ModuleAzureIndirectRequestDTO}
   * @memberof CollectorConfigRequestDTO
   */
  azureIndirect?: ModuleAzureIndirectRequestDTO;
}
/**
 *
 * @export
 * @interface CollectorConfigResponseDTO
 */
export interface CollectorConfigResponseDTO {
  /**
   *
   * @type {string}
   * @memberof CollectorConfigResponseDTO
   */
  id?: string;
  /**
   *
   * @type {ModuleHierarchyResponseDTO}
   * @memberof CollectorConfigResponseDTO
   */
  hierarchy?: ModuleHierarchyResponseDTO;
  /**
   *
   * @type {ModuleAWSResponseDTO}
   * @memberof CollectorConfigResponseDTO
   */
  aws?: ModuleAWSResponseDTO;
  /**
   *
   * @type {ModuleGenericResponseDTO}
   * @memberof CollectorConfigResponseDTO
   */
  generic?: ModuleGenericResponseDTO;
  /**
   *
   * @type {ModuleAzureIndirectResponseDTO}
   * @memberof CollectorConfigResponseDTO
   */
  azureIndirect?: ModuleAzureIndirectResponseDTO;
}
/**
 *
 * @export
 * @interface CollectorOracle
 */
export interface CollectorOracle {
  /**
   *
   * @type {string}
   * @memberof CollectorOracle
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof CollectorOracle
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof CollectorOracle
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CollectorOracle
   */
  accountId: string;
  /**
   *
   * @type {CollectorOracleCredentials}
   * @memberof CollectorOracle
   */
  credentials: CollectorOracleCredentials;
  /**
   *
   * @type {string} An ISO date string
   * @memberof CollectorOracle
   */
  updatedAt?: string;
  /**
   *
   * @type {string}
   * @memberof CollectorOracle
   */
  status: string;
  /**
   *
   * @type {boolean}
   * @memberof CollectorOracle
   */
  canDisable: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CollectorOracle
   */
  owned: boolean;
  /**
   *
   * @type {string}
   * @memberof CollectorOracle
   */
  priceAdjustmentsSet: CollectorOraclePriceAdjustmentsSetEnum;
  /**
   *
   * @type {boolean}
   * @memberof CollectorOracle
   */
  isEnabled: boolean;
}

/**
 * @export
 * @enum {string}
 */

export const CollectorOraclePriceAdjustmentsSetEnum = {
  SET: "SET",
  NOT_SET: "NOT_SET",
  NOT_APPLICABLE: "NOT_APPLICABLE",
} as const;
export type CollectorOraclePriceAdjustmentsSetEnum =
  typeof CollectorOraclePriceAdjustmentsSetEnum[keyof typeof CollectorOraclePriceAdjustmentsSetEnum];

/**
 *
 * @export
 * @interface CollectorOracleCredentials
 */
export interface CollectorOracleCredentials {
  /**
   *
   * @type {string}
   * @memberof CollectorOracleCredentials
   */
  user: string;
  /**
   *
   * @type {string}
   * @memberof CollectorOracleCredentials
   */
  tenancy: string;
  /**
   *
   * @type {string}
   * @memberof CollectorOracleCredentials
   */
  region: string;
  /**
   *
   * @type {string}
   * @memberof CollectorOracleCredentials
   */
  apiKey: string;
  /**
   *
   * @type {string}
   * @memberof CollectorOracleCredentials
   */
  fingerprint: string;
}
/**
 *
 * @export
 * @interface CollectorOracleCredentialsInput
 */
export interface CollectorOracleCredentialsInput {
  /**
   *
   * @type {string}
   * @memberof CollectorOracleCredentialsInput
   */
  user?: string;
  /**
   *
   * @type {string}
   * @memberof CollectorOracleCredentialsInput
   */
  tenancy?: string;
  /**
   *
   * @type {string}
   * @memberof CollectorOracleCredentialsInput
   */
  region?: string;
  /**
   *
   * @type {string}
   * @memberof CollectorOracleCredentialsInput
   */
  apiKey?: string;
  /**
   *
   * @type {string}
   * @memberof CollectorOracleCredentialsInput
   */
  fingerprint?: string;
}
/**
 *
 * @export
 * @interface CollectorOracleInput
 */
export interface CollectorOracleInput {
  /**
   *
   * @type {string}
   * @memberof CollectorOracleInput
   */
  name?: string;
  /**
   *
   * @type {CollectorOracleCredentialsInput}
   * @memberof CollectorOracleInput
   */
  credentials?: CollectorOracleCredentialsInput;
}
/**
 *
 * @export
 * @interface CollectorPriceAdjustment
 */
export interface CollectorPriceAdjustment {
  /**
   *
   * @type {PriceAdjustmentFilterResponse}
   * @memberof CollectorPriceAdjustment
   */
  filter: PriceAdjustmentFilterResponse;
  /**
   *
   * @type {boolean}
   * @memberof CollectorPriceAdjustment
   */
  inherited: boolean;
  /**
   *
   * @type {Array<TimedPriceAdjustment>}
   * @memberof CollectorPriceAdjustment
   */
  adjustments: Array<TimedPriceAdjustment>;
}
/**
 *
 * @export
 * @interface CollectorPriceAdjustmentResponseDTO
 */
export interface CollectorPriceAdjustmentResponseDTO {
  /**
   *
   * @type {string}
   * @memberof CollectorPriceAdjustmentResponseDTO
   */
  key: string;
  /**
   *
   * @type {PriceAdjustmentFilterResponse}
   * @memberof CollectorPriceAdjustmentResponseDTO
   */
  filter: PriceAdjustmentFilterResponse;
  /**
   *
   * @type {CollectorTypedPriceAdjustments}
   * @memberof CollectorPriceAdjustmentResponseDTO
   */
  adjustments: CollectorTypedPriceAdjustments;
}
/**
 *
 * @export
 * @interface CollectorPriceAdjustments
 */
export interface CollectorPriceAdjustments {
  /**
   *
   * @type {CollectorPriceAdjustment}
   * @memberof CollectorPriceAdjustments
   */
  applied: CollectorPriceAdjustment;
  /**
   *
   * @type {Array<CollectorPriceAdjustment>}
   * @memberof CollectorPriceAdjustments
   */
  overwritten: Array<CollectorPriceAdjustment>;
}
/**
 *
 * @export
 * @interface CollectorResponseDTO
 */
export interface CollectorResponseDTO {
  /**
   *
   * @type {object}
   * @memberof CollectorResponseDTO
   */
  credentials?: object;
  /**
   *
   * @type {boolean}
   * @memberof CollectorResponseDTO
   */
  canDisable: boolean;
  /**
   *
   * @type {string}
   * @memberof CollectorResponseDTO
   */
  priceAdjustmentsSet: CollectorResponseDTOPriceAdjustmentsSetEnum;
  /**
   *
   * @type {boolean}
   * @memberof CollectorResponseDTO
   */
  owned: boolean;
  /**
   *
   * @type {string} An ISO date string
   * @memberof CollectorResponseDTO
   */
  updatedAt?: string;
  /**
   *
   * @type {string}
   * @memberof CollectorResponseDTO
   */
  status: string;
  /**
   *
   * @type {boolean}
   * @memberof CollectorResponseDTO
   */
  isEnabled: boolean;
  /**
   *
   * @type {string}
   * @memberof CollectorResponseDTO
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CollectorResponseDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof CollectorResponseDTO
   */
  type: string;
}

/**
 * @export
 * @enum {string}
 */

export const CollectorResponseDTOPriceAdjustmentsSetEnum = {
  SET: "SET",
  NOT_SET: "NOT_SET",
  NOT_APPLICABLE: "NOT_APPLICABLE",
} as const;
export type CollectorResponseDTOPriceAdjustmentsSetEnum =
  typeof CollectorResponseDTOPriceAdjustmentsSetEnum[keyof typeof CollectorResponseDTOPriceAdjustmentsSetEnum];

/**
 *
 * @export
 * @interface CollectorTypedPriceAdjustments
 */
export interface CollectorTypedPriceAdjustments {
  /**
   *
   * @type {CollectorPriceAdjustments}
   * @memberof CollectorTypedPriceAdjustments
   */
  discount?: CollectorPriceAdjustments;
  /**
   *
   * @type {CollectorPriceAdjustments}
   * @memberof CollectorTypedPriceAdjustments
   */
  margin?: CollectorPriceAdjustments;
  /**
   *
   * @type {CollectorPriceAdjustments}
   * @memberof CollectorTypedPriceAdjustments
   */
  markup?: CollectorPriceAdjustments;
}
/**
 *
 * @export
 * @interface CollectorUploadCredentials
 */
export interface CollectorUploadCredentials {
  /**
   *
   * @type {string}
   * @memberof CollectorUploadCredentials
   */
  apiKey?: string;
}
/**
 *
 * @export
 * @interface CollectorUploadResponseDTO
 */
export interface CollectorUploadResponseDTO {
  /**
   *
   * @type {string}
   * @memberof CollectorUploadResponseDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof CollectorUploadResponseDTO
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof CollectorUploadResponseDTO
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CollectorUploadResponseDTO
   */
  accountId: string;
  /**
   *
   * @type {CollectorUploadCredentials}
   * @memberof CollectorUploadResponseDTO
   */
  credentials: CollectorUploadCredentials;
  /**
   *
   * @type {string} An ISO date string
   * @memberof CollectorUploadResponseDTO
   */
  updatedAt?: string;
  /**
   *
   * @type {string}
   * @memberof CollectorUploadResponseDTO
   */
  status: string;
  /**
   *
   * @type {boolean}
   * @memberof CollectorUploadResponseDTO
   */
  canDisable: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CollectorUploadResponseDTO
   */
  owned: boolean;
  /**
   *
   * @type {string}
   * @memberof CollectorUploadResponseDTO
   */
  priceAdjustmentsSet: CollectorUploadResponseDTOPriceAdjustmentsSetEnum;
  /**
   *
   * @type {boolean}
   * @memberof CollectorUploadResponseDTO
   */
  isEnabled: boolean;
}

/**
 * @export
 * @enum {string}
 */

export const CollectorUploadResponseDTOPriceAdjustmentsSetEnum = {
  SET: "SET",
  NOT_SET: "NOT_SET",
  NOT_APPLICABLE: "NOT_APPLICABLE",
} as const;
export type CollectorUploadResponseDTOPriceAdjustmentsSetEnum =
  typeof CollectorUploadResponseDTOPriceAdjustmentsSetEnum[keyof typeof CollectorUploadResponseDTOPriceAdjustmentsSetEnum];

/**
 *
 * @export
 * @interface ConfigResponseDTO
 */
export interface ConfigResponseDTO {
  /**
   *
   * @type {string}
   * @memberof ConfigResponseDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ConfigResponseDTO
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof ConfigResponseDTO
   */
  typeParam?: string;
  /**
   *
   * @type {object}
   * @memberof ConfigResponseDTO
   */
  config: object;
  /**
   *
   * @type {boolean}
   * @memberof ConfigResponseDTO
   */
  shared?: boolean;
}
/**
 *
 * @export
 * @interface ConvertedMoneyDTO
 */
export interface ConvertedMoneyDTO {
  /**
   *
   * @type {MoneyDTO}
   * @memberof ConvertedMoneyDTO
   */
  requested?: MoneyDTO;
  /**
   *
   * @type {Array<MoneyDTO>}
   * @memberof ConvertedMoneyDTO
   */
  original?: Array<MoneyDTO>;
}
/**
 *
 * @export
 * @interface CostMap
 */
export interface CostMap {
  /**
   *
   * @type {ConvertedMoneyDTO}
   * @memberof CostMap
   */
  cost?: ConvertedMoneyDTO;
  /**
   *
   * @type {ConvertedMoneyDTO}
   * @memberof CostMap
   */
  discount?: ConvertedMoneyDTO;
  /**
   *
   * @type {ConvertedMoneyDTO}
   * @memberof CostMap
   */
  crossCharging?: ConvertedMoneyDTO;
  /**
   *
   * @type {ConvertedMoneyDTO}
   * @memberof CostMap
   */
  sales?: ConvertedMoneyDTO;
  /**
   *
   * @type {ConvertedMoneyDTO}
   * @memberof CostMap
   */
  list?: ConvertedMoneyDTO;
  /**
   *
   * @type {ConvertedMoneyDTO}
   * @memberof CostMap
   */
  profit?: ConvertedMoneyDTO;
}
/**
 *
 * @export
 * @interface CreateAwsCollectorParametersResponseDTO
 */
export interface CreateAwsCollectorParametersResponseDTO {
  /**
   *
   * @type {string}
   * @memberof CreateAwsCollectorParametersResponseDTO
   */
  accountId: string;
  /**
   *
   * @type {string}
   * @memberof CreateAwsCollectorParametersResponseDTO
   */
  roleId: string;
}
/**
 *
 * @export
 * @interface CreateBatchProjectionRequestDTO
 */
export interface CreateBatchProjectionRequestDTO {
  /**
   *
   * @type {Array<BatchProjectionAttributeValueDTO>}
   * @memberof CreateBatchProjectionRequestDTO
   */
  attributeValues: Array<BatchProjectionAttributeValueDTO>;
  /**
   *
   * @type {Existing | New}
   * @memberof CreateBatchProjectionRequestDTO
   */
  organization: Existing | New | null;
}
/**
 *
 * @export
 * @interface CreateBudgetAlertRequestDTO
 */
export interface CreateBudgetAlertRequestDTO {
  /**
   *
   * @type {string}
   * @memberof CreateBudgetAlertRequestDTO
   */
  alertType?: string;
  /**
   *
   * @type {string}
   * @memberof CreateBudgetAlertRequestDTO
   */
  thresholdType?: string;
  /**
   *
   * @type {number}
   * @memberof CreateBudgetAlertRequestDTO
   */
  thresholdValue: number;
}
/**
 *
 * @export
 * @interface CreateBudgetRequestDTO
 */
export interface CreateBudgetRequestDTO {
  /**
   *
   * @type {number}
   * @memberof CreateBudgetRequestDTO
   */
  value: number;
  /**
   *
   * @type {string}
   * @memberof CreateBudgetRequestDTO
   */
  currency: string;
  /**
   *
   * @type {string}
   * @memberof CreateBudgetRequestDTO
   */
  budgetType: string;
}
/**
 *
 * @export
 * @interface CreateCollectorUploadRequestDTO
 */
export interface CreateCollectorUploadRequestDTO {
  /**
   *
   * @type {string}
   * @memberof CreateCollectorUploadRequestDTO
   */
  source?: string;
  /**
   *
   * @type {string}
   * @memberof CreateCollectorUploadRequestDTO
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CreateCollectorUploadRequestDTO
   */
  accountId?: string;
}
/**
 *
 * @export
 * @interface CreateConfigRequestDTO
 */
export interface CreateConfigRequestDTO {
  /**
   *
   * @type {object}
   * @memberof CreateConfigRequestDTO
   */
  config: object;
  /**
   *
   * @type {boolean}
   * @memberof CreateConfigRequestDTO
   */
  shared?: boolean;
}
/**
 *
 * @export
 * @interface CreateCustomerPortalDTO
 */
export interface CreateCustomerPortalDTO {
  /**
   *
   * @type {string}
   * @memberof CreateCustomerPortalDTO
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof CreateCustomerPortalDTO
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateCustomerPortalDTO
   */
  canAddDataSource: boolean;
}
/**
 *
 * @export
 * @interface CreateEmailNotificationRequestDTO
 */
export interface CreateEmailNotificationRequestDTO {
  /**
   *
   * @type {string}
   * @memberof CreateEmailNotificationRequestDTO
   */
  recipient: string;
}
/**
 *
 * @export
 * @interface CreateOrUpdateOrganizationalUnitContactRequestDTO
 */
export interface CreateOrUpdateOrganizationalUnitContactRequestDTO {
  /**
   *
   * @type {string}
   * @memberof CreateOrUpdateOrganizationalUnitContactRequestDTO
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOrUpdateOrganizationalUnitContactRequestDTO
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOrUpdateOrganizationalUnitContactRequestDTO
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOrUpdateOrganizationalUnitContactRequestDTO
   */
  administrativeArea?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOrUpdateOrganizationalUnitContactRequestDTO
   */
  locality?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOrUpdateOrganizationalUnitContactRequestDTO
   */
  postalCode?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOrUpdateOrganizationalUnitContactRequestDTO
   */
  thoroughfare?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOrUpdateOrganizationalUnitContactRequestDTO
   */
  premise?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOrUpdateOrganizationalUnitContactRequestDTO
   */
  chamberOfCommerce?: string;
  /**
   *
   * @type {ReplyToContactDTO}
   * @memberof CreateOrUpdateOrganizationalUnitContactRequestDTO
   */
  replyTo?: ReplyToContactDTO;
}
/**
 *
 * @export
 * @interface CreateOrganizationalUnitRequestDTO
 */
export interface CreateOrganizationalUnitRequestDTO {
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationalUnitRequestDTO
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationalUnitRequestDTO
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationalUnitRequestDTO
   */
  parentId?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationalUnitRequestDTO
   */
  reference?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationalUnitRequestDTO
   */
  unitType?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationalUnitRequestDTO
   */
  grossType?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationalUnitRequestDTO
   */
  basePrice?: string;
}
/**
 *
 * @export
 * @interface CreateOrganizationsForCollectorRequestParams
 */
export interface CreateOrganizationsForCollectorRequestParams {
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationsForCollectorRequestParams
   */
  type?: CreateOrganizationsForCollectorRequestParamsTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationsForCollectorRequestParams
   */
  basePrice?: CreateOrganizationsForCollectorRequestParamsBasePriceEnum;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationsForCollectorRequestParams
   */
  grossType?: CreateOrganizationsForCollectorRequestParamsGrossTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationsForCollectorRequestParams
   */
  rootTag?: string;
}

/**
 * @export
 * @enum {string}
 */

export const CreateOrganizationsForCollectorRequestParamsTypeEnum = {
  CUSTOMER: "CUSTOMER",
  INTERNAL: "INTERNAL",
  RESELLER: "RESELLER",
} as const;
export type CreateOrganizationsForCollectorRequestParamsTypeEnum =
  typeof CreateOrganizationsForCollectorRequestParamsTypeEnum[keyof typeof CreateOrganizationsForCollectorRequestParamsTypeEnum];
/**
 * @export
 * @enum {string}
 */

export const CreateOrganizationsForCollectorRequestParamsBasePriceEnum = {
  RETAIL: "RETAIL",
  INVOICED: "INVOICED",
  DISCOUNT: "DISCOUNT",
} as const;
export type CreateOrganizationsForCollectorRequestParamsBasePriceEnum =
  typeof CreateOrganizationsForCollectorRequestParamsBasePriceEnum[keyof typeof CreateOrganizationsForCollectorRequestParamsBasePriceEnum];
/**
 * @export
 * @enum {string}
 */

export const CreateOrganizationsForCollectorRequestParamsGrossTypeEnum = {
  MARGIN: "MARGIN",
  MARKUP: "MARKUP",
} as const;
export type CreateOrganizationsForCollectorRequestParamsGrossTypeEnum =
  typeof CreateOrganizationsForCollectorRequestParamsGrossTypeEnum[keyof typeof CreateOrganizationsForCollectorRequestParamsGrossTypeEnum];

/**
 *
 * @export
 * @interface CreatePriceAdjustmentRequestDTO
 */
export interface CreatePriceAdjustmentRequestDTO {
  /**
   *
   * @type {PriceAdjustmentFilterRequestDTO}
   * @memberof CreatePriceAdjustmentRequestDTO
   */
  filter: PriceAdjustmentFilterRequestDTO;
  /**
   *
   * @type {string}
   * @memberof CreatePriceAdjustmentRequestDTO
   */
  adjustmentType: string;
  /**
   *
   * @type {number}
   * @memberof CreatePriceAdjustmentRequestDTO
   */
  adjustmentValue: number;
}
/**
 *
 * @export
 * @interface CreateWorkspaceUserRequestDTO
 */
export interface CreateWorkspaceUserRequestDTO {
  /**
   *
   * @type {string}
   * @memberof CreateWorkspaceUserRequestDTO
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreateWorkspaceUserRequestDTO
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof CreateWorkspaceUserRequestDTO
   */
  role: CreateWorkspaceUserRequestDTORoleEnum;
}

/**
 * @export
 * @enum {string}
 */

export const CreateWorkspaceUserRequestDTORoleEnum = {
  ADMIN: "ADMIN",
  READ: "READ",
} as const;
export type CreateWorkspaceUserRequestDTORoleEnum =
  typeof CreateWorkspaceUserRequestDTORoleEnum[keyof typeof CreateWorkspaceUserRequestDTORoleEnum];

/**
 *
 * @export
 * @interface Datasource
 */
export interface Datasource {
  /**
   *
   * @type {string}
   * @memberof Datasource
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof Datasource
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof Datasource
   */
  indirect?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Datasource
   */
  isIncludeAll?: boolean;
}
/**
 *
 * @export
 * @interface DiscountResponseDTO
 */
export interface DiscountResponseDTO {
  /**
   *
   * @type {string}
   * @memberof DiscountResponseDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof DiscountResponseDTO
   */
  productId: string;
  /**
   *
   * @type {number}
   * @memberof DiscountResponseDTO
   */
  discount: number;
  /**
   *
   * @type {string} An ISO date string
   * @memberof DiscountResponseDTO
   */
  startDate: string;
  /**
   *
   * @type {string} An ISO date string
   * @memberof DiscountResponseDTO
   */
  endDate?: string;
  /**
   *
   * @type {string}
   * @memberof DiscountResponseDTO
   */
  workspaceId: string;
}
/**
 *
 * @export
 * @interface EditWorkspaceUserRequestDTO
 */
export interface EditWorkspaceUserRequestDTO {
  /**
   *
   * @type {string}
   * @memberof EditWorkspaceUserRequestDTO
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof EditWorkspaceUserRequestDTO
   */
  role?: EditWorkspaceUserRequestDTORoleEnum;
}

/**
 * @export
 * @enum {string}
 */

export const EditWorkspaceUserRequestDTORoleEnum = {
  ADMIN: "ADMIN",
  READ: "READ",
} as const;
export type EditWorkspaceUserRequestDTORoleEnum =
  typeof EditWorkspaceUserRequestDTORoleEnum[keyof typeof EditWorkspaceUserRequestDTORoleEnum];

/**
 *
 * @export
 * @interface EnableMFADTO
 */
export interface EnableMFADTO {
  /**
   *
   * @type {string}
   * @memberof EnableMFADTO
   */
  secret?: string;
  /**
   *
   * @type {string}
   * @memberof EnableMFADTO
   */
  code?: string;
}
/**
 *
 * @export
 * @interface Existing
 */
export interface Existing extends BatchProjectionOrganizationDTO {
  /**
   *
   * @type {string}
   * @memberof Existing
   */
  type: ExistingTypeEnum;
  /**
   *
   * @type {string}
   * @memberof Existing
   */
  id: string;
}

/**
 * @export
 * @enum {string}
 */

export const ExistingTypeEnum = {
  existing: "existing",
} as const;
export type ExistingTypeEnum =
  typeof ExistingTypeEnum[keyof typeof ExistingTypeEnum];

/**
 *
 * @export
 * @interface ExistingAllOf
 */
export interface ExistingAllOf {
  /**
   *
   * @type {string}
   * @memberof ExistingAllOf
   */
  type?: ExistingAllOfTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ExistingAllOf
   */
  id?: string;
}

/**
 * @export
 * @enum {string}
 */

export const ExistingAllOfTypeEnum = {
  existing: "existing",
} as const;
export type ExistingAllOfTypeEnum =
  typeof ExistingAllOfTypeEnum[keyof typeof ExistingAllOfTypeEnum];

/**
 *
 * @export
 * @interface ExtendedLineItemDTO
 */
export interface ExtendedLineItemDTO {
  /**
   *
   * @type {string}
   * @memberof ExtendedLineItemDTO
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof ExtendedLineItemDTO
   */
  organization: string;
  /**
   *
   * @type {string}
   * @memberof ExtendedLineItemDTO
   */
  organizationRef: string;
  /**
   *
   * @type {string}
   * @memberof ExtendedLineItemDTO
   */
  department: string;
  /**
   *
   * @type {string}
   * @memberof ExtendedLineItemDTO
   */
  departmentRef: string;
  /**
   *
   * @type {string}
   * @memberof ExtendedLineItemDTO
   */
  location: string;
  /**
   *
   * @type {string}
   * @memberof ExtendedLineItemDTO
   */
  costType: string;
  /**
   *
   * @type {string}
   * @memberof ExtendedLineItemDTO
   */
  service: string;
  /**
   *
   * @type {string}
   * @memberof ExtendedLineItemDTO
   */
  detail: string;
  /**
   *
   * @type {string}
   * @memberof ExtendedLineItemDTO
   */
  cloud: string;
  /**
   *
   * @type {string}
   * @memberof ExtendedLineItemDTO
   */
  invoice: string;
  /**
   *
   * @type {string}
   * @memberof ExtendedLineItemDTO
   */
  datasource: string;
  /**
   *
   * @type {string}
   * @memberof ExtendedLineItemDTO
   */
  accountId: string;
  /**
   *
   * @type {string}
   * @memberof ExtendedLineItemDTO
   */
  billingMonth: string;
  /**
   *
   * @type {string}
   * @memberof ExtendedLineItemDTO
   */
  invoicePeriod: string;
  /**
   *
   * @type {string}
   * @memberof ExtendedLineItemDTO
   */
  anchorDate: string;
  /**
   *
   * @type {string}
   * @memberof ExtendedLineItemDTO
   */
  currency: string;
  /**
   *
   * @type {number}
   * @memberof ExtendedLineItemDTO
   */
  quantity?: number;
  /**
   *
   * @type {CostMap}
   * @memberof ExtendedLineItemDTO
   */
  costs: CostMap;
  /**
   *
   * @type {string}
   * @memberof ExtendedLineItemDTO
   */
  collectorId: string;
  /**
   *
   * @type {object}
   * @memberof ExtendedLineItemDTO
   */
  attributes: object;
}
/**
 *
 * @export
 * @interface ExtendedLineItemDTOV2
 */
export interface ExtendedLineItemDTOV2 {
  /**
   *
   * @type {LineItemProperty}
   * @memberof ExtendedLineItemDTOV2
   */
  type: LineItemProperty;
  /**
   *
   * @type {LineItemProperty}
   * @memberof ExtendedLineItemDTOV2
   */
  unit: LineItemProperty;
  /**
   *
   * @type {LineItemProperty}
   * @memberof ExtendedLineItemDTOV2
   */
  organization: LineItemProperty;
  /**
   *
   * @type {LineItemProperty}
   * @memberof ExtendedLineItemDTOV2
   */
  organizationRef: LineItemProperty;
  /**
   *
   * @type {LineItemProperty}
   * @memberof ExtendedLineItemDTOV2
   */
  department: LineItemProperty;
  /**
   *
   * @type {LineItemProperty}
   * @memberof ExtendedLineItemDTOV2
   */
  departmentRef: LineItemProperty;
  /**
   *
   * @type {LineItemProperty}
   * @memberof ExtendedLineItemDTOV2
   */
  location: LineItemProperty;
  /**
   *
   * @type {LineItemProperty}
   * @memberof ExtendedLineItemDTOV2
   */
  costType: LineItemProperty;
  /**
   *
   * @type {LineItemProperty}
   * @memberof ExtendedLineItemDTOV2
   */
  service: LineItemProperty;
  /**
   *
   * @type {LineItemProperty}
   * @memberof ExtendedLineItemDTOV2
   */
  detail: LineItemProperty;
  /**
   *
   * @type {LineItemProperty}
   * @memberof ExtendedLineItemDTOV2
   */
  cloud: LineItemProperty;
  /**
   *
   * @type {LineItemProperty}
   * @memberof ExtendedLineItemDTOV2
   */
  invoice: LineItemProperty;
  /**
   *
   * @type {LineItemProperty}
   * @memberof ExtendedLineItemDTOV2
   */
  datasource: LineItemProperty;
  /**
   *
   * @type {LineItemProperty}
   * @memberof ExtendedLineItemDTOV2
   */
  accountId: LineItemProperty;
  /**
   *
   * @type {LineItemProperty}
   * @memberof ExtendedLineItemDTOV2
   */
  billingMonth: LineItemProperty;
  /**
   *
   * @type {LineItemProperty}
   * @memberof ExtendedLineItemDTOV2
   */
  invoicePeriod: LineItemProperty;
  /**
   *
   * @type {LineItemProperty}
   * @memberof ExtendedLineItemDTOV2
   */
  anchorDate: LineItemProperty;
  /**
   *
   * @type {LineItemProperty}
   * @memberof ExtendedLineItemDTOV2
   */
  currency: LineItemProperty;
  /**
   *
   * @type {number}
   * @memberof ExtendedLineItemDTOV2
   */
  quantity?: number;
  /**
   *
   * @type {CostMap}
   * @memberof ExtendedLineItemDTOV2
   */
  costs: CostMap;
  /**
   *
   * @type {string}
   * @memberof ExtendedLineItemDTOV2
   */
  collectorId: string;
  /**
   *
   * @type {object}
   * @memberof ExtendedLineItemDTOV2
   */
  attributes: object;
}
/**
 *
 * @export
 * @interface Filter
 */
export interface Filter {
  /**
   *
   * @type {string}
   * @memberof Filter
   */
  key: string;
  /**
   *
   * @type {string}
   * @memberof Filter
   */
  value?: string;
  /**
   *
   * @type {string}
   * @memberof Filter
   */
  mappedTo?: string;
}
/**
 *
 * @export
 * @interface FilterRequestDTO
 */
export interface FilterRequestDTO {
  /**
   *
   * @type {FilterRequestValueDTO}
   * @memberof FilterRequestDTO
   */
  account: FilterRequestValueDTO;
  /**
   *
   * @type {FilterRequestValueDTO}
   * @memberof FilterRequestDTO
   */
  customerTag: FilterRequestValueDTO;
  /**
   *
   * @type {FilterRequestValueDTO}
   * @memberof FilterRequestDTO
   */
  applicationTag: FilterRequestValueDTO;
}
/**
 *
 * @export
 * @interface FilterRequestValueDTO
 */
export interface FilterRequestValueDTO {
  /**
   *
   * @type {string}
   * @memberof FilterRequestValueDTO
   */
  value: string;
}
/**
 *
 * @export
 * @interface FilterResponseDTO
 */
export interface FilterResponseDTO {
  /**
   *
   * @type {FilterResponseValueDTO}
   * @memberof FilterResponseDTO
   */
  account: FilterResponseValueDTO;
  /**
   *
   * @type {FilterResponseValueDTO}
   * @memberof FilterResponseDTO
   */
  customerTag: FilterResponseValueDTO;
  /**
   *
   * @type {FilterResponseValueDTO}
   * @memberof FilterResponseDTO
   */
  applicationTag: FilterResponseValueDTO;
}
/**
 *
 * @export
 * @interface FilterResponseValueDTO
 */
export interface FilterResponseValueDTO {
  /**
   *
   * @type {string}
   * @memberof FilterResponseValueDTO
   */
  value: string;
  /**
   *
   * @type {string}
   * @memberof FilterResponseValueDTO
   */
  displayValue: string;
}
/**
 *
 * @export
 * @interface ForceMFADTO
 */
export interface ForceMFADTO {
  /**
   *
   * @type {string}
   * @memberof ForceMFADTO
   */
  type?: ForceMFADTOTypeEnum;
}

/**
 * @export
 * @enum {string}
 */

export const ForceMFADTOTypeEnum = {
  USER: "USER",
  MANAGED: "MANAGED",
} as const;
export type ForceMFADTOTypeEnum =
  typeof ForceMFADTOTypeEnum[keyof typeof ForceMFADTOTypeEnum];

/**
 *
 * @export
 * @interface GOOGLECredentialsDTO
 */
export interface GOOGLECredentialsDTO extends CloudCredentialsDTO {
  /**
   *
   * @type {string}
   * @memberof GOOGLECredentialsDTO
   */
  type: GOOGLECredentialsDTOTypeEnum;
  /**
   *
   * @type {string}
   * @memberof GOOGLECredentialsDTO
   */
  dataset: string;
  /**
   *
   * @type {object}
   * @memberof GOOGLECredentialsDTO
   */
  apiKey: object;
}

/**
 * @export
 * @enum {string}
 */

export const GOOGLECredentialsDTOTypeEnum = {
  GOOGLE: "GOOGLE",
} as const;
export type GOOGLECredentialsDTOTypeEnum =
  typeof GOOGLECredentialsDTOTypeEnum[keyof typeof GOOGLECredentialsDTOTypeEnum];

/**
 *
 * @export
 * @interface GOOGLECredentialsDTOAllOf
 */
export interface GOOGLECredentialsDTOAllOf {
  /**
   *
   * @type {string}
   * @memberof GOOGLECredentialsDTOAllOf
   */
  type?: GOOGLECredentialsDTOAllOfTypeEnum;
  /**
   *
   * @type {string}
   * @memberof GOOGLECredentialsDTOAllOf
   */
  dataset?: string;
  /**
   *
   * @type {object}
   * @memberof GOOGLECredentialsDTOAllOf
   */
  apiKey?: object;
}

/**
 * @export
 * @enum {string}
 */

export const GOOGLECredentialsDTOAllOfTypeEnum = {
  GOOGLE: "GOOGLE",
} as const;
export type GOOGLECredentialsDTOAllOfTypeEnum =
  typeof GOOGLECredentialsDTOAllOfTypeEnum[keyof typeof GOOGLECredentialsDTOAllOfTypeEnum];

/**
 *
 * @export
 * @interface GetMFASecretDTO
 */
export interface GetMFASecretDTO {
  /**
   *
   * @type {string}
   * @memberof GetMFASecretDTO
   */
  secret?: string;
  /**
   *
   * @type {string}
   * @memberof GetMFASecretDTO
   */
  qrUrl?: string;
  /**
   *
   * @type {string}
   * @memberof GetMFASecretDTO
   */
  key?: string;
}
/**
 *
 * @export
 * @interface GoogleCollectorRequestDTO
 */
export interface GoogleCollectorRequestDTO {
  /**
   *
   * @type {string}
   * @memberof GoogleCollectorRequestDTO
   */
  name?: string;
  /**
   *
   * @type {GoogleCollectorRequestDTOCredentials}
   * @memberof GoogleCollectorRequestDTO
   */
  credentials?: GoogleCollectorRequestDTOCredentials;
}
/**
 *
 * @export
 * @interface GoogleCollectorRequestDTOCredentials
 */
export interface GoogleCollectorRequestDTOCredentials {
  /**
   *
   * @type {string}
   * @memberof GoogleCollectorRequestDTOCredentials
   */
  dataset?: string;
  /**
   *
   * @type {object}
   * @memberof GoogleCollectorRequestDTOCredentials
   */
  apiKey?: object;
}
/**
 *
 * @export
 * @interface GoogleCollectorResponseDTO
 */
export interface GoogleCollectorResponseDTO {
  /**
   *
   * @type {string}
   * @memberof GoogleCollectorResponseDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof GoogleCollectorResponseDTO
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof GoogleCollectorResponseDTO
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof GoogleCollectorResponseDTO
   */
  accountId: string;
  /**
   *
   * @type {GoogleCollectorResponseDTOCredentials}
   * @memberof GoogleCollectorResponseDTO
   */
  credentials: GoogleCollectorResponseDTOCredentials;
  /**
   *
   * @type {string} An ISO date string
   * @memberof GoogleCollectorResponseDTO
   */
  updatedAt?: string;
  /**
   *
   * @type {string}
   * @memberof GoogleCollectorResponseDTO
   */
  status: string;
  /**
   *
   * @type {boolean}
   * @memberof GoogleCollectorResponseDTO
   */
  canDisable: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GoogleCollectorResponseDTO
   */
  owned: boolean;
  /**
   *
   * @type {string}
   * @memberof GoogleCollectorResponseDTO
   */
  priceAdjustmentsSet: GoogleCollectorResponseDTOPriceAdjustmentsSetEnum;
  /**
   *
   * @type {boolean}
   * @memberof GoogleCollectorResponseDTO
   */
  isEnabled: boolean;
}

/**
 * @export
 * @enum {string}
 */

export const GoogleCollectorResponseDTOPriceAdjustmentsSetEnum = {
  SET: "SET",
  NOT_SET: "NOT_SET",
  NOT_APPLICABLE: "NOT_APPLICABLE",
} as const;
export type GoogleCollectorResponseDTOPriceAdjustmentsSetEnum =
  typeof GoogleCollectorResponseDTOPriceAdjustmentsSetEnum[keyof typeof GoogleCollectorResponseDTOPriceAdjustmentsSetEnum];

/**
 *
 * @export
 * @interface GoogleCollectorResponseDTOCredentials
 */
export interface GoogleCollectorResponseDTOCredentials {
  /**
   *
   * @type {string}
   * @memberof GoogleCollectorResponseDTOCredentials
   */
  dataset: string;
}
/**
 *
 * @export
 * @interface INGRAMCACredentialsDTO
 */
export interface INGRAMCACredentialsDTO extends CloudCredentialsDTO {
  /**
   *
   * @type {string}
   * @memberof INGRAMCACredentialsDTO
   */
  type: INGRAMCACredentialsDTOTypeEnum;
  /**
   *
   * @type {string}
   * @memberof INGRAMCACredentialsDTO
   */
  adDomain: string;
  /**
   *
   * @type {string}
   * @memberof INGRAMCACredentialsDTO
   */
  appId: string;
  /**
   *
   * @type {string}
   * @memberof INGRAMCACredentialsDTO
   */
  appKey: string;
  /**
   *
   * @type {string}
   * @memberof INGRAMCACredentialsDTO
   */
  ingramMarketplace: string;
  /**
   *
   * @type {string}
   * @memberof INGRAMCACredentialsDTO
   */
  ingramApiKey: string;
  /**
   *
   * @type {string}
   * @memberof INGRAMCACredentialsDTO
   */
  ingramUsername: string;
  /**
   *
   * @type {string}
   * @memberof INGRAMCACredentialsDTO
   */
  ingramPassword: string;
}

/**
 * @export
 * @enum {string}
 */

export const INGRAMCACredentialsDTOTypeEnum = {
  INGRAMCA: "INGRAMCA",
} as const;
export type INGRAMCACredentialsDTOTypeEnum =
  typeof INGRAMCACredentialsDTOTypeEnum[keyof typeof INGRAMCACredentialsDTOTypeEnum];

/**
 *
 * @export
 * @interface INGRAMCACredentialsDTOAllOf
 */
export interface INGRAMCACredentialsDTOAllOf {
  /**
   *
   * @type {string}
   * @memberof INGRAMCACredentialsDTOAllOf
   */
  type?: INGRAMCACredentialsDTOAllOfTypeEnum;
  /**
   *
   * @type {string}
   * @memberof INGRAMCACredentialsDTOAllOf
   */
  adDomain?: string;
  /**
   *
   * @type {string}
   * @memberof INGRAMCACredentialsDTOAllOf
   */
  appId?: string;
  /**
   *
   * @type {string}
   * @memberof INGRAMCACredentialsDTOAllOf
   */
  appKey?: string;
  /**
   *
   * @type {string}
   * @memberof INGRAMCACredentialsDTOAllOf
   */
  ingramMarketplace?: string;
  /**
   *
   * @type {string}
   * @memberof INGRAMCACredentialsDTOAllOf
   */
  ingramApiKey?: string;
  /**
   *
   * @type {string}
   * @memberof INGRAMCACredentialsDTOAllOf
   */
  ingramUsername?: string;
  /**
   *
   * @type {string}
   * @memberof INGRAMCACredentialsDTOAllOf
   */
  ingramPassword?: string;
}

/**
 * @export
 * @enum {string}
 */

export const INGRAMCACredentialsDTOAllOfTypeEnum = {
  INGRAMCA: "INGRAMCA",
} as const;
export type INGRAMCACredentialsDTOAllOfTypeEnum =
  typeof INGRAMCACredentialsDTOAllOfTypeEnum[keyof typeof INGRAMCACredentialsDTOAllOfTypeEnum];

/**
 *
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
  /**
   *
   * @type {Blob}
   * @memberof InlineObject
   */
  file: Blob;
  /**
   *
   * @type {UploadCsvRequestDTO}
   * @memberof InlineObject
   */
  data: UploadCsvRequestDTO;
}
/**
 *
 * @export
 * @interface InvoiceResponseDTO
 */
export interface InvoiceResponseDTO {
  /**
   *
   * @type {string}
   * @memberof InvoiceResponseDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceResponseDTO
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceResponseDTO
   */
  origin: string;
  /**
   *
   * @type {BillingPeriod}
   * @memberof InvoiceResponseDTO
   */
  billingPeriod: BillingPeriod;
  /**
   *
   * @type {string} An ISO date string
   * @memberof InvoiceResponseDTO
   */
  date: string;
  /**
   *
   * @type {number}
   * @memberof InvoiceResponseDTO
   */
  cost: number;
  /**
   *
   * @type {number}
   * @memberof InvoiceResponseDTO
   */
  invoicedCost?: number;
  /**
   *
   * @type {string}
   * @memberof InvoiceResponseDTO
   */
  currency: string;
  /**
   *
   * @type {boolean}
   * @memberof InvoiceResponseDTO
   */
  pdfAvailable: boolean;
  /**
   *
   * @type {boolean}
   * @memberof InvoiceResponseDTO
   */
  reconAvailable: boolean;
  /**
   *
   * @type {Datasource}
   * @memberof InvoiceResponseDTO
   */
  datasource: Datasource;
}
/**
 *
 * @export
 * @interface LineItemKeysParams
 */
export interface LineItemKeysParams {
  /**
   *
   * @type {Array<QueryFilter>}
   * @memberof LineItemKeysParams
   */
  filters: Array<QueryFilter>;
}
/**
 *
 * @export
 * @interface LineItemOptionsParams
 */
export interface LineItemOptionsParams {
  /**
   *
   * @type {string}
   * @memberof LineItemOptionsParams
   */
  segment: string;
  /**
   *
   * @type {Array<QueryFilter>}
   * @memberof LineItemOptionsParams
   */
  filters: Array<QueryFilter>;
}
/**
 *
 * @export
 * @interface LineItemProperty
 */
export interface LineItemProperty {
  /**
   *
   * @type {string}
   * @memberof LineItemProperty
   */
  value: string;
  /**
   *
   * @type {string}
   * @memberof LineItemProperty
   */
  displayValue: string;
}
/**
 *
 * @export
 * @interface LineItemQueryKeysResponseDTO
 */
export interface LineItemQueryKeysResponseDTO {
  /**
   *
   * @type {QueryResponseCategory}
   * @memberof LineItemQueryKeysResponseDTO
   */
  category: QueryResponseCategory;
  /**
   *
   * @type {Array<QueryResponseAttribute>}
   * @memberof LineItemQueryKeysResponseDTO
   */
  attributes: Array<QueryResponseAttribute>;
}
/**
 *
 * @export
 * @interface LineItemQueryOptionsResponseDTO
 */
export interface LineItemQueryOptionsResponseDTO {
  /**
   *
   * @type {QueryResponseSegment}
   * @memberof LineItemQueryOptionsResponseDTO
   */
  segment: QueryResponseSegment;
  /**
   *
   * @type {Array<QueryResponseOption>}
   * @memberof LineItemQueryOptionsResponseDTO
   */
  options: Array<QueryResponseOption>;
}
/**
 *
 * @export
 * @interface LineItemQueryParams
 */
export interface LineItemQueryParams {
  /**
   *
   * @type {Array<QueryDimension>}
   * @memberof LineItemQueryParams
   */
  dimensions: Array<QueryDimension>;
  /**
   *
   * @type {string}
   * @memberof LineItemQueryParams
   */
  currency?: string;
  /**
   *
   * @type {Array<QueryFilter>}
   * @memberof LineItemQueryParams
   */
  filters: Array<QueryFilter>;
}
/**
 *
 * @export
 * @interface LineItemQueryResponseDTO
 */
export interface LineItemQueryResponseDTO {
  /**
   *
   * @type {QueryResponseDimension}
   * @memberof LineItemQueryResponseDTO
   */
  dimension: QueryResponseDimension;
  /**
   *
   * @type {LineItemQueryResponseDTOCurrency}
   * @memberof LineItemQueryResponseDTO
   */
  currency?: LineItemQueryResponseDTOCurrency;
  /**
   *
   * @type {Array<QuerySegment>}
   * @memberof LineItemQueryResponseDTO
   */
  arguments: Array<QuerySegment>;
}
/**
 *
 * @export
 * @interface LineItemQueryResponseDTOCurrency
 */
export interface LineItemQueryResponseDTOCurrency {
  /**
   *
   * @type {string}
   * @memberof LineItemQueryResponseDTOCurrency
   */
  currencyCode?: string;
  /**
   *
   * @type {number}
   * @memberof LineItemQueryResponseDTOCurrency
   */
  defaultFractionDigits?: number;
  /**
   *
   * @type {number}
   * @memberof LineItemQueryResponseDTOCurrency
   */
  numericCode?: number;
  /**
   *
   * @type {string}
   * @memberof LineItemQueryResponseDTOCurrency
   */
  symbol?: string;
  /**
   *
   * @type {string}
   * @memberof LineItemQueryResponseDTOCurrency
   */
  displayName?: string;
}
/**
 *
 * @export
 * @interface LineItemSegmentParams
 */
export interface LineItemSegmentParams {
  /**
   *
   * @type {Array<string>}
   * @memberof LineItemSegmentParams
   */
  segments: Array<string>;
  /**
   *
   * @type {string}
   * @memberof LineItemSegmentParams
   */
  currency?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof LineItemSegmentParams
   */
  values: Array<LineItemSegmentParamsValuesEnum>;
  /**
   *
   * @type {Array<QueryFilter>}
   * @memberof LineItemSegmentParams
   */
  filters: Array<QueryFilter>;
}

/**
 * @export
 * @enum {string}
 */

export const LineItemSegmentParamsValuesEnum = {
  COST: "COST",
  DISCOUNT: "DISCOUNT",
  SALES: "SALES",
  CROSS_CHARGING: "CROSS_CHARGING",
  RETAIL: "RETAIL",
  QUANTITY: "QUANTITY",
} as const;
export type LineItemSegmentParamsValuesEnum =
  typeof LineItemSegmentParamsValuesEnum[keyof typeof LineItemSegmentParamsValuesEnum];

/**
 *
 * @export
 * @interface ListOptionsForBatchProjectionsRequestDTO
 */
export interface ListOptionsForBatchProjectionsRequestDTO {
  /**
   *
   * @type {string}
   * @memberof ListOptionsForBatchProjectionsRequestDTO
   */
  collectorId: string;
  /**
   *
   * @type {string}
   * @memberof ListOptionsForBatchProjectionsRequestDTO
   */
  rootFilter?: string;
}
/**
 *
 * @export
 * @interface M365ConnectorCredentialsDTO
 */
export interface M365ConnectorCredentialsDTO extends CloudCredentialsDTO {
  /**
   *
   * @type {string}
   * @memberof M365ConnectorCredentialsDTO
   */
  type: M365ConnectorCredentialsDTOTypeEnum;
  /**
   *
   * @type {string}
   * @memberof M365ConnectorCredentialsDTO
   */
  adDomain: string;
}

/**
 * @export
 * @enum {string}
 */

export const M365ConnectorCredentialsDTOTypeEnum = {
  M365_CONNECTOR: "M365_CONNECTOR",
} as const;
export type M365ConnectorCredentialsDTOTypeEnum =
  typeof M365ConnectorCredentialsDTOTypeEnum[keyof typeof M365ConnectorCredentialsDTOTypeEnum];

/**
 *
 * @export
 * @interface M365ConnectorCredentialsDTOAllOf
 */
export interface M365ConnectorCredentialsDTOAllOf {
  /**
   *
   * @type {string}
   * @memberof M365ConnectorCredentialsDTOAllOf
   */
  type?: M365ConnectorCredentialsDTOAllOfTypeEnum;
  /**
   *
   * @type {string}
   * @memberof M365ConnectorCredentialsDTOAllOf
   */
  adDomain?: string;
}

/**
 * @export
 * @enum {string}
 */

export const M365ConnectorCredentialsDTOAllOfTypeEnum = {
  M365_CONNECTOR: "M365_CONNECTOR",
} as const;
export type M365ConnectorCredentialsDTOAllOfTypeEnum =
  typeof M365ConnectorCredentialsDTOAllOfTypeEnum[keyof typeof M365ConnectorCredentialsDTOAllOfTypeEnum];

/**
 *
 * @export
 * @interface ModuleAWSRequestDTO
 */
export interface ModuleAWSRequestDTO {
  /**
   *
   * @type {string}
   * @memberof ModuleAWSRequestDTO
   */
  costExplorerGroupByTagKey?: string;
}
/**
 *
 * @export
 * @interface ModuleAWSResponseDTO
 */
export interface ModuleAWSResponseDTO {
  /**
   *
   * @type {object}
   * @memberof ModuleAWSResponseDTO
   */
  options?: object;
  /**
   *
   * @type {ModuleAWSResponseValuesDTO}
   * @memberof ModuleAWSResponseDTO
   */
  values?: ModuleAWSResponseValuesDTO;
}
/**
 *
 * @export
 * @interface ModuleAWSResponseValuesDTO
 */
export interface ModuleAWSResponseValuesDTO {
  /**
   *
   * @type {string}
   * @memberof ModuleAWSResponseValuesDTO
   */
  costExplorerGroupByTagKey?: string;
}
/**
 *
 * @export
 * @interface ModuleAzureIndirectOptionsResponseDTO
 */
export interface ModuleAzureIndirectOptionsResponseDTO {
  /**
   *
   * @type {boolean}
   * @memberof ModuleAzureIndirectOptionsResponseDTO
   */
  indirect: boolean;
}
/**
 *
 * @export
 * @interface ModuleAzureIndirectRequestDTO
 */
export interface ModuleAzureIndirectRequestDTO {
  /**
   *
   * @type {number}
   * @memberof ModuleAzureIndirectRequestDTO
   */
  billingCycleAnchorDate?: number;
}
/**
 *
 * @export
 * @interface ModuleAzureIndirectResponseDTO
 */
export interface ModuleAzureIndirectResponseDTO {
  /**
   *
   * @type {ModuleAzureIndirectOptionsResponseDTO}
   * @memberof ModuleAzureIndirectResponseDTO
   */
  options?: ModuleAzureIndirectOptionsResponseDTO;
  /**
   *
   * @type {ModuleAzureIndirectResponseValuesDTO}
   * @memberof ModuleAzureIndirectResponseDTO
   */
  values?: ModuleAzureIndirectResponseValuesDTO;
}
/**
 *
 * @export
 * @interface ModuleAzureIndirectResponseValuesDTO
 */
export interface ModuleAzureIndirectResponseValuesDTO {
  /**
   *
   * @type {number}
   * @memberof ModuleAzureIndirectResponseValuesDTO
   */
  billingCycleAnchorDate?: number;
}
/**
 *
 * @export
 * @interface ModuleGenericRequestDTO
 */
export interface ModuleGenericRequestDTO {
  /**
   *
   * @type {string} An ISO date string
   * @memberof ModuleGenericRequestDTO
   */
  collectFrom?: string;
  /**
   *
   * @type {boolean}
   * @memberof ModuleGenericRequestDTO
   */
  includeAllData?: boolean;
}
/**
 *
 * @export
 * @interface ModuleGenericResponseDTO
 */
export interface ModuleGenericResponseDTO {
  /**
   *
   * @type {ModuleGenericResponseOptionsDTO}
   * @memberof ModuleGenericResponseDTO
   */
  options?: ModuleGenericResponseOptionsDTO;
  /**
   *
   * @type {ModuleGenericResponseValueDTO}
   * @memberof ModuleGenericResponseDTO
   */
  values?: ModuleGenericResponseValueDTO;
}
/**
 *
 * @export
 * @interface ModuleGenericResponseOptionsDTO
 */
export interface ModuleGenericResponseOptionsDTO {
  /**
   *
   * @type {boolean}
   * @memberof ModuleGenericResponseOptionsDTO
   */
  canToggleIncludeAll?: boolean;
}
/**
 *
 * @export
 * @interface ModuleGenericResponseValueDTO
 */
export interface ModuleGenericResponseValueDTO {
  /**
   *
   * @type {string} An ISO date string
   * @memberof ModuleGenericResponseValueDTO
   */
  collectFrom?: string;
  /**
   *
   * @type {boolean}
   * @memberof ModuleGenericResponseValueDTO
   */
  includeAllData?: boolean;
}
/**
 *
 * @export
 * @interface ModuleHierarchyRequestAttributeDTO
 */
export interface ModuleHierarchyRequestAttributeDTO {
  /**
   *
   * @type {string}
   * @memberof ModuleHierarchyRequestAttributeDTO
   */
  type: string;
  /**
   *
   * @type {ModuleHierarchyRequestAttributeParamDTO}
   * @memberof ModuleHierarchyRequestAttributeDTO
   */
  param?: ModuleHierarchyRequestAttributeParamDTO;
}
/**
 *
 * @export
 * @interface ModuleHierarchyRequestAttributeParamDTO
 */
export interface ModuleHierarchyRequestAttributeParamDTO {
  /**
   *
   * @type {string}
   * @memberof ModuleHierarchyRequestAttributeParamDTO
   */
  id: string;
}
/**
 *
 * @export
 * @interface ModuleHierarchyRequestDTO
 */
export interface ModuleHierarchyRequestDTO {
  /**
   *
   * @type {ModuleHierarchyRequestAttributeDTO}
   * @memberof ModuleHierarchyRequestDTO
   */
  rootHierarchy: ModuleHierarchyRequestAttributeDTO;
  /**
   *
   * @type {Array<ModuleHierarchyRequestAttributeDTO>}
   * @memberof ModuleHierarchyRequestDTO
   */
  detailHierarchy: Array<ModuleHierarchyRequestAttributeDTO>;
}
/**
 *
 * @export
 * @interface ModuleHierarchyResponseAttributeDTO
 */
export interface ModuleHierarchyResponseAttributeDTO {
  /**
   *
   * @type {string}
   * @memberof ModuleHierarchyResponseAttributeDTO
   */
  type: string;
  /**
   *
   * @type {ModuleHierarchyResponseValuesAttributeParamDTO}
   * @memberof ModuleHierarchyResponseAttributeDTO
   */
  param?: ModuleHierarchyResponseValuesAttributeParamDTO;
  /**
   *
   * @type {string}
   * @memberof ModuleHierarchyResponseAttributeDTO
   */
  displayValue: string;
}
/**
 *
 * @export
 * @interface ModuleHierarchyResponseDTO
 */
export interface ModuleHierarchyResponseDTO {
  /**
   *
   * @type {ModuleHierarchyResponseOptionsDTO}
   * @memberof ModuleHierarchyResponseDTO
   */
  options: ModuleHierarchyResponseOptionsDTO;
  /**
   *
   * @type {ModuleHierarchyResponseValuesDTO}
   * @memberof ModuleHierarchyResponseDTO
   */
  values?: ModuleHierarchyResponseValuesDTO;
}
/**
 *
 * @export
 * @interface ModuleHierarchyResponseOptionsAttributeDTO
 */
export interface ModuleHierarchyResponseOptionsAttributeDTO {
  /**
   *
   * @type {string}
   * @memberof ModuleHierarchyResponseOptionsAttributeDTO
   */
  type: string;
  /**
   *
   * @type {ModuleHierarchyResponseOptionsAttributeParamOptionsDTO}
   * @memberof ModuleHierarchyResponseOptionsAttributeDTO
   */
  param?: ModuleHierarchyResponseOptionsAttributeParamOptionsDTO;
  /**
   *
   * @type {Array<ModuleHierarchyResponseOptionsAttributeParamOptionsDTO>}
   * @memberof ModuleHierarchyResponseOptionsAttributeDTO
   */
  paramOptions?: Array<ModuleHierarchyResponseOptionsAttributeParamOptionsDTO>;
  /**
   *
   * @type {number}
   * @memberof ModuleHierarchyResponseOptionsAttributeDTO
   */
  maxVersionsOfAttribute: number;
  /**
   *
   * @type {string}
   * @memberof ModuleHierarchyResponseOptionsAttributeDTO
   */
  displayValue: string;
}
/**
 *
 * @export
 * @interface ModuleHierarchyResponseOptionsAttributeParamOptionsDTO
 */
export interface ModuleHierarchyResponseOptionsAttributeParamOptionsDTO {
  /**
   *
   * @type {string}
   * @memberof ModuleHierarchyResponseOptionsAttributeParamOptionsDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ModuleHierarchyResponseOptionsAttributeParamOptionsDTO
   */
  displayValue: string;
  /**
   *
   * @type {boolean}
   * @memberof ModuleHierarchyResponseOptionsAttributeParamOptionsDTO
   */
  ignore: boolean;
  /**
   *
   * @type {string} An ISO date string
   * @memberof ModuleHierarchyResponseOptionsAttributeParamOptionsDTO
   */
  firstSeen: string;
}
/**
 *
 * @export
 * @interface ModuleHierarchyResponseOptionsDTO
 */
export interface ModuleHierarchyResponseOptionsDTO {
  /**
   *
   * @type {Array<ModuleHierarchyResponseOptionsAttributeDTO>}
   * @memberof ModuleHierarchyResponseOptionsDTO
   */
  attributes: Array<ModuleHierarchyResponseOptionsAttributeDTO>;
  /**
   *
   * @type {Array<ModuleHierarchyResponseOptionsAttributeDTO>}
   * @memberof ModuleHierarchyResponseOptionsDTO
   */
  defaultAttributes?: Array<ModuleHierarchyResponseOptionsAttributeDTO>;
  /**
   *
   * @type {number}
   * @memberof ModuleHierarchyResponseOptionsDTO
   */
  maxDepthOfDetail?: number;
}
/**
 *
 * @export
 * @interface ModuleHierarchyResponseValuesAttributeParamDTO
 */
export interface ModuleHierarchyResponseValuesAttributeParamDTO {
  /**
   *
   * @type {string}
   * @memberof ModuleHierarchyResponseValuesAttributeParamDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ModuleHierarchyResponseValuesAttributeParamDTO
   */
  displayValue: string;
}
/**
 *
 * @export
 * @interface ModuleHierarchyResponseValuesDTO
 */
export interface ModuleHierarchyResponseValuesDTO {
  /**
   *
   * @type {ModuleHierarchyResponseAttributeDTO}
   * @memberof ModuleHierarchyResponseValuesDTO
   */
  rootHierarchy: ModuleHierarchyResponseAttributeDTO;
  /**
   *
   * @type {Array<ModuleHierarchyResponseAttributeDTO>}
   * @memberof ModuleHierarchyResponseValuesDTO
   */
  detailHierarchy: Array<ModuleHierarchyResponseAttributeDTO>;
}
/**
 *
 * @export
 * @interface MoneyDTO
 */
export interface MoneyDTO {
  /**
   *
   * @type {number}
   * @memberof MoneyDTO
   */
  amount?: number;
  /**
   *
   * @type {LineItemQueryResponseDTOCurrency}
   * @memberof MoneyDTO
   */
  currency?: LineItemQueryResponseDTOCurrency;
}
/**
 *
 * @export
 * @interface MonthlyCostDTO
 */
export interface MonthlyCostDTO {
  /**
   *
   * @type {number}
   * @memberof MonthlyCostDTO
   */
  cost?: number;
  /**
   *
   * @type {string}
   * @memberof MonthlyCostDTO
   */
  collectorId?: string;
  /**
   *
   * @type {string}
   * @memberof MonthlyCostDTO
   */
  billingMonth?: string;
}
/**
 *
 * @export
 * @interface NestedLineItemDTO
 */
export interface NestedLineItemDTO {
  /**
   *
   * @type {string}
   * @memberof NestedLineItemDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof NestedLineItemDTO
   */
  dimension: string;
  /**
   *
   * @type {string}
   * @memberof NestedLineItemDTO
   */
  description: string;
  /**
   *
   * @type {Array<NestedLineItemDTO>}
   * @memberof NestedLineItemDTO
   */
  childLineItems: Array<NestedLineItemDTO>;
  /**
   *
   * @type {Array<ExtendedLineItemDTO>}
   * @memberof NestedLineItemDTO
   */
  detailedLineItems?: Array<ExtendedLineItemDTO>;
  /**
   *
   * @type {number}
   * @memberof NestedLineItemDTO
   */
  totalQuantity?: number;
  /**
   *
   * @type {string}
   * @memberof NestedLineItemDTO
   */
  attributeId: string;
  /**
   *
   * @type {string}
   * @memberof NestedLineItemDTO
   */
  attributeValueId: string;
  /**
   *
   * @type {CostMap}
   * @memberof NestedLineItemDTO
   */
  total: CostMap;
}
/**
 *
 * @export
 * @interface NestedLineItemDTOV2
 */
export interface NestedLineItemDTOV2 {
  /**
   *
   * @type {string}
   * @memberof NestedLineItemDTOV2
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof NestedLineItemDTOV2
   */
  dimension: string;
  /**
   *
   * @type {LineItemProperty}
   * @memberof NestedLineItemDTOV2
   */
  description: LineItemProperty;
  /**
   *
   * @type {Array<NestedLineItemDTOV2>}
   * @memberof NestedLineItemDTOV2
   */
  childLineItems: Array<NestedLineItemDTOV2>;
  /**
   *
   * @type {Array<ExtendedLineItemDTOV2>}
   * @memberof NestedLineItemDTOV2
   */
  detailedLineItems?: Array<ExtendedLineItemDTOV2>;
  /**
   *
   * @type {number}
   * @memberof NestedLineItemDTOV2
   */
  totalQuantity?: number;
  /**
   *
   * @type {string}
   * @memberof NestedLineItemDTOV2
   */
  attributeId: string;
  /**
   *
   * @type {string}
   * @memberof NestedLineItemDTOV2
   */
  attributeValueId: string;
  /**
   *
   * @type {CostMap}
   * @memberof NestedLineItemDTOV2
   */
  total: CostMap;
}
/**
 *
 * @export
 * @interface NestedLineItemDTOV3
 */
export interface NestedLineItemDTOV3 {
  /**
   *
   * @type {string}
   * @memberof NestedLineItemDTOV3
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof NestedLineItemDTOV3
   */
  label: string;
  /**
   *
   * @type {Array<NestedLineItemDTOV3>}
   * @memberof NestedLineItemDTOV3
   */
  children: Array<NestedLineItemDTOV3>;
  /**
   *
   * @type {number}
   * @memberof NestedLineItemDTOV3
   */
  qty?: number;
  /**
   *
   * @type {ValueCostMap}
   * @memberof NestedLineItemDTOV3
   */
  total: ValueCostMap;
}
/**
 *
 * @export
 * @interface New
 */
export interface New extends BatchProjectionOrganizationDTO {
  /**
   *
   * @type {string}
   * @memberof New
   */
  type: NewTypeEnum;
  /**
   *
   * @type {string}
   * @memberof New
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof New
   */
  unitType: NewUnitTypeEnum;
}

/**
 * @export
 * @enum {string}
 */

export const NewTypeEnum = {
  new: "new",
} as const;
export type NewTypeEnum = typeof NewTypeEnum[keyof typeof NewTypeEnum];
/**
 * @export
 * @enum {string}
 */

export const NewUnitTypeEnum = {
  CUSTOMER: "CUSTOMER",
  INTERNAL: "INTERNAL",
  RESELLER: "RESELLER",
} as const;
export type NewUnitTypeEnum =
  typeof NewUnitTypeEnum[keyof typeof NewUnitTypeEnum];

/**
 *
 * @export
 * @interface NewAllOf
 */
export interface NewAllOf {
  /**
   *
   * @type {string}
   * @memberof NewAllOf
   */
  type?: NewAllOfTypeEnum;
  /**
   *
   * @type {string}
   * @memberof NewAllOf
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof NewAllOf
   */
  unitType?: NewAllOfUnitTypeEnum;
}

/**
 * @export
 * @enum {string}
 */

export const NewAllOfTypeEnum = {
  new: "new",
} as const;
export type NewAllOfTypeEnum =
  typeof NewAllOfTypeEnum[keyof typeof NewAllOfTypeEnum];
/**
 * @export
 * @enum {string}
 */

export const NewAllOfUnitTypeEnum = {
  CUSTOMER: "CUSTOMER",
  INTERNAL: "INTERNAL",
  RESELLER: "RESELLER",
} as const;
export type NewAllOfUnitTypeEnum =
  typeof NewAllOfUnitTypeEnum[keyof typeof NewAllOfUnitTypeEnum];

/**
 *
 * @export
 * @interface NewPasswordForm
 */
export interface NewPasswordForm {
  /**
   *
   * @type {string}
   * @memberof NewPasswordForm
   */
  oldPassword?: string;
  /**
   *
   * @type {string}
   * @memberof NewPasswordForm
   */
  newPassword?: string;
}
/**
 *
 * @export
 * @interface NotificationResponseDTO
 */
export interface NotificationResponseDTO {
  /**
   *
   * @type {string}
   * @memberof NotificationResponseDTO
   */
  text?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationResponseDTO
   */
  topic?: string;
  /**
   *
   * @type {string} An ISO date string
   * @memberof NotificationResponseDTO
   */
  createdAt?: string;
  /**
   *
   * @type {boolean}
   * @memberof NotificationResponseDTO
   */
  read?: boolean;
}
/**
 *
 * @export
 * @interface OCICredentialsDTO
 */
export interface OCICredentialsDTO extends CloudCredentialsDTO {
  /**
   *
   * @type {string}
   * @memberof OCICredentialsDTO
   */
  type: OCICredentialsDTOTypeEnum;
  /**
   *
   * @type {string}
   * @memberof OCICredentialsDTO
   */
  user: string;
  /**
   *
   * @type {string}
   * @memberof OCICredentialsDTO
   */
  tenancy: string;
  /**
   *
   * @type {string}
   * @memberof OCICredentialsDTO
   */
  region: string;
  /**
   *
   * @type {string}
   * @memberof OCICredentialsDTO
   */
  apiKey: string;
  /**
   *
   * @type {string}
   * @memberof OCICredentialsDTO
   */
  fingerprint: string;
}

/**
 * @export
 * @enum {string}
 */

export const OCICredentialsDTOTypeEnum = {
  OCI: "OCI",
} as const;
export type OCICredentialsDTOTypeEnum =
  typeof OCICredentialsDTOTypeEnum[keyof typeof OCICredentialsDTOTypeEnum];

/**
 *
 * @export
 * @interface OCICredentialsDTOAllOf
 */
export interface OCICredentialsDTOAllOf {
  /**
   *
   * @type {string}
   * @memberof OCICredentialsDTOAllOf
   */
  type?: OCICredentialsDTOAllOfTypeEnum;
  /**
   *
   * @type {string}
   * @memberof OCICredentialsDTOAllOf
   */
  user?: string;
  /**
   *
   * @type {string}
   * @memberof OCICredentialsDTOAllOf
   */
  tenancy?: string;
  /**
   *
   * @type {string}
   * @memberof OCICredentialsDTOAllOf
   */
  region?: string;
  /**
   *
   * @type {string}
   * @memberof OCICredentialsDTOAllOf
   */
  apiKey?: string;
  /**
   *
   * @type {string}
   * @memberof OCICredentialsDTOAllOf
   */
  fingerprint?: string;
}

/**
 * @export
 * @enum {string}
 */

export const OCICredentialsDTOAllOfTypeEnum = {
  OCI: "OCI",
} as const;
export type OCICredentialsDTOAllOfTypeEnum =
  typeof OCICredentialsDTOAllOfTypeEnum[keyof typeof OCICredentialsDTOAllOfTypeEnum];

/**
 *
 * @export
 * @interface OrgAndWorkspaceIdsDTO
 */
export interface OrgAndWorkspaceIdsDTO {
  /**
   *
   * @type {Array<string>}
   * @memberof OrgAndWorkspaceIdsDTO
   */
  orgIds?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof OrgAndWorkspaceIdsDTO
   */
  workspaceIds?: Array<string>;
}
/**
 *
 * @export
 * @interface OrganizationPriceAdjustment
 */
export interface OrganizationPriceAdjustment {
  /**
   *
   * @type {OrganizationPriceAdjustmentsOrganization}
   * @memberof OrganizationPriceAdjustment
   */
  organization: OrganizationPriceAdjustmentsOrganization;
  /**
   *
   * @type {PriceAdjustmentFilterResponse}
   * @memberof OrganizationPriceAdjustment
   */
  filter: PriceAdjustmentFilterResponse;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationPriceAdjustment
   */
  inherited: boolean;
  /**
   *
   * @type {Array<TimedPriceAdjustment>}
   * @memberof OrganizationPriceAdjustment
   */
  adjustments: Array<TimedPriceAdjustment>;
}
/**
 *
 * @export
 * @interface OrganizationPriceAdjustments
 */
export interface OrganizationPriceAdjustments {
  /**
   *
   * @type {OrganizationPriceAdjustment}
   * @memberof OrganizationPriceAdjustments
   */
  applied: OrganizationPriceAdjustment;
  /**
   *
   * @type {Array<OrganizationPriceAdjustment>}
   * @memberof OrganizationPriceAdjustments
   */
  overwritten: Array<OrganizationPriceAdjustment>;
}
/**
 *
 * @export
 * @interface OrganizationPriceAdjustmentsOrganization
 */
export interface OrganizationPriceAdjustmentsOrganization {
  /**
   *
   * @type {string}
   * @memberof OrganizationPriceAdjustmentsOrganization
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationPriceAdjustmentsOrganization
   */
  name: string;
}
/**
 *
 * @export
 * @interface OrganizationTypedPriceAdjustments
 */
export interface OrganizationTypedPriceAdjustments {
  /**
   *
   * @type {OrganizationPriceAdjustments}
   * @memberof OrganizationTypedPriceAdjustments
   */
  discount?: OrganizationPriceAdjustments;
  /**
   *
   * @type {OrganizationPriceAdjustments}
   * @memberof OrganizationTypedPriceAdjustments
   */
  margin?: OrganizationPriceAdjustments;
  /**
   *
   * @type {OrganizationPriceAdjustments}
   * @memberof OrganizationTypedPriceAdjustments
   */
  markup?: OrganizationPriceAdjustments;
}
/**
 *
 * @export
 * @interface OrganizationalUnitContactDTO
 */
export interface OrganizationalUnitContactDTO {
  /**
   *
   * @type {string}
   * @memberof OrganizationalUnitContactDTO
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationalUnitContactDTO
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationalUnitContactDTO
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationalUnitContactDTO
   */
  administrativeArea?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationalUnitContactDTO
   */
  locality?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationalUnitContactDTO
   */
  postalCode?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationalUnitContactDTO
   */
  thoroughfare?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationalUnitContactDTO
   */
  premise?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationalUnitContactDTO
   */
  chamberOfCommerce?: string;
  /**
   *
   * @type {ReplyToContactDTO}
   * @memberof OrganizationalUnitContactDTO
   */
  replyTo?: ReplyToContactDTO;
}
/**
 *
 * @export
 * @interface OrganizationalUnitResponseDTO
 */
export interface OrganizationalUnitResponseDTO {
  /**
   *
   * @type {string}
   * @memberof OrganizationalUnitResponseDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationalUnitResponseDTO
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationalUnitResponseDTO
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationalUnitResponseDTO
   */
  unitType: string;
  /**
   *
   * @type {Array<OrganizationalUnitResponseDTO>}
   * @memberof OrganizationalUnitResponseDTO
   */
  children?: Array<OrganizationalUnitResponseDTO>;
  /**
   *
   * @type {string}
   * @memberof OrganizationalUnitResponseDTO
   */
  reference?: string;
  /**
   *
   * @type {Array<ProjectionResponse>}
   * @memberof OrganizationalUnitResponseDTO
   */
  projections?: Array<ProjectionResponse>;
  /**
   *
   * @type {Array<PortalResponseDTO>}
   * @memberof OrganizationalUnitResponseDTO
   */
  portals?: Array<PortalResponseDTO>;
  /**
   *
   * @type {Array<PortalResponseDTO>}
   * @memberof OrganizationalUnitResponseDTO
   */
  managedPortals?: Array<PortalResponseDTO>;
  /**
   *
   * @type {BudgetResponseDTO}
   * @memberof OrganizationalUnitResponseDTO
   */
  budget?: BudgetResponseDTO;
  /**
   *
   * @type {string}
   * @memberof OrganizationalUnitResponseDTO
   */
  grossType?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationalUnitResponseDTO
   */
  basePrice?: string;
}
/**
 *
 * @export
 * @interface PasswordResetForm
 */
export interface PasswordResetForm {
  /**
   *
   * @type {string}
   * @memberof PasswordResetForm
   */
  resetCode?: string;
  /**
   *
   * @type {string}
   * @memberof PasswordResetForm
   */
  password?: string;
}
/**
 *
 * @export
 * @interface PipelineRun
 */
export interface PipelineRun {
  /**
   *
   * @type {string}
   * @memberof PipelineRun
   */
  id: string;
  /**
   *
   * @type {string} An ISO date string
   * @memberof PipelineRun
   */
  startDate: string;
  /**
   *
   * @type {string} An ISO date string
   * @memberof PipelineRun
   */
  endDate?: string;
  /**
   *
   * @type {string}
   * @memberof PipelineRun
   */
  status: string;
}
/**
 *
 * @export
 * @interface PipelineRunEvent
 */
export interface PipelineRunEvent {
  /**
   *
   * @type {string}
   * @memberof PipelineRunEvent
   */
  id: string;
  /**
   *
   * @type {string} An ISO date string
   * @memberof PipelineRunEvent
   */
  timestamp: string;
  /**
   *
   * @type {string}
   * @memberof PipelineRunEvent
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof PipelineRunEvent
   */
  state: string;
  /**
   *
   * @type {string}
   * @memberof PipelineRunEvent
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof PipelineRunEvent
   */
  stepId: string;
  /**
   *
   * @type {string}
   * @memberof PipelineRunEvent
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof PipelineRunEvent
   */
  messageType: string;
  /**
   *
   * @type {string}
   * @memberof PipelineRunEvent
   */
  origin: string;
}
/**
 *
 * @export
 * @interface PortalInvitationResponseDTO
 */
export interface PortalInvitationResponseDTO {
  /**
   *
   * @type {string}
   * @memberof PortalInvitationResponseDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PortalInvitationResponseDTO
   */
  inviteBy: string;
  /**
   *
   * @type {string}
   * @memberof PortalInvitationResponseDTO
   */
  inviteFor: string;
}
/**
 *
 * @export
 * @interface PortalResponseDTO
 */
export interface PortalResponseDTO {
  /**
   *
   * @type {string}
   * @memberof PortalResponseDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PortalResponseDTO
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof PortalResponseDTO
   */
  status: string;
  /**
   *
   * @type {boolean}
   * @memberof PortalResponseDTO
   */
  mfaEnabled: boolean;
  /**
   *
   * @type {string}
   * @memberof PortalResponseDTO
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof PortalResponseDTO
   */
  associatedThrough?: string;
  /**
   *
   * @type {string}
   * @memberof PortalResponseDTO
   */
  organizationId: string;
  /**
   *
   * @type {string}
   * @memberof PortalResponseDTO
   */
  organizationName: string;
  /**
   *
   * @type {number}
   * @memberof PortalResponseDTO
   */
  usersInWorkspace?: number;
  /**
   *
   * @type {string} An ISO date string
   * @memberof PortalResponseDTO
   */
  lastActive?: string;
  /**
   *
   * @type {string}
   * @memberof PortalResponseDTO
   */
  name?: string;
  /**
   *
   * @type {boolean}
   * @memberof PortalResponseDTO
   */
  canAddDataSource: boolean;
}
/**
 *
 * @export
 * @interface PriceAdjustmentFilterRequestDTO
 */
export interface PriceAdjustmentFilterRequestDTO {
  /**
   *
   * @type {FilterRequestValueDTO}
   * @memberof PriceAdjustmentFilterRequestDTO
   */
  source: FilterRequestValueDTO;
  /**
   *
   * @type {FilterRequestValueDTO}
   * @memberof PriceAdjustmentFilterRequestDTO
   */
  costType: FilterRequestValueDTO;
  /**
   *
   * @type {FilterRequestValueDTO}
   * @memberof PriceAdjustmentFilterRequestDTO
   */
  service: FilterRequestValueDTO;
  /**
   *
   * @type {FilterRequestValueDTO}
   * @memberof PriceAdjustmentFilterRequestDTO
   */
  detail: FilterRequestValueDTO;
}
/**
 *
 * @export
 * @interface PriceAdjustmentFilterResponse
 */
export interface PriceAdjustmentFilterResponse {
  /**
   *
   * @type {FilterResponseValueDTO}
   * @memberof PriceAdjustmentFilterResponse
   */
  source: FilterResponseValueDTO;
  /**
   *
   * @type {FilterResponseValueDTO}
   * @memberof PriceAdjustmentFilterResponse
   */
  costType: FilterResponseValueDTO;
  /**
   *
   * @type {FilterResponseValueDTO}
   * @memberof PriceAdjustmentFilterResponse
   */
  service: FilterResponseValueDTO;
  /**
   *
   * @type {FilterResponseValueDTO}
   * @memberof PriceAdjustmentFilterResponse
   */
  detail: FilterResponseValueDTO;
}
/**
 *
 * @export
 * @interface PriceAdjustmentResponseDTO
 */
export interface PriceAdjustmentResponseDTO {
  /**
   *
   * @type {string}
   * @memberof PriceAdjustmentResponseDTO
   */
  key: string;
  /**
   *
   * @type {PriceAdjustmentFilterResponse}
   * @memberof PriceAdjustmentResponseDTO
   */
  filter: PriceAdjustmentFilterResponse;
  /**
   *
   * @type {OrganizationalUnitResponseDTO}
   * @memberof PriceAdjustmentResponseDTO
   */
  organization: OrganizationalUnitResponseDTO;
  /**
   *
   * @type {OrganizationTypedPriceAdjustments}
   * @memberof PriceAdjustmentResponseDTO
   */
  adjustments: OrganizationTypedPriceAdjustments;
}
/**
 *
 * @export
 * @interface ProcessEventQueueDTO
 */
export interface ProcessEventQueueDTO {
  /**
   *
   * @type {string} An ISO date string
   * @memberof ProcessEventQueueDTO
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof ProcessEventQueueDTO
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof ProcessEventQueueDTO
   */
  message?: string;
  /**
   *
   * @type {boolean}
   * @memberof ProcessEventQueueDTO
   */
  isActive: boolean;
}
/**
 *
 * @export
 * @interface ProjectionOrganizationRequestDTO
 */
export interface ProjectionOrganizationRequestDTO {
  /**
   *
   * @type {string}
   * @memberof ProjectionOrganizationRequestDTO
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof ProjectionOrganizationRequestDTO
   */
  weight: number;
}
/**
 *
 * @export
 * @interface ProjectionRequestDTO
 */
export interface ProjectionRequestDTO {
  /**
   *
   * @type {FilterRequestDTO}
   * @memberof ProjectionRequestDTO
   */
  filter: FilterRequestDTO;
  /**
   *
   * @type {Array<ProjectionOrganizationRequestDTO>}
   * @memberof ProjectionRequestDTO
   */
  mappedTo: Array<ProjectionOrganizationRequestDTO>;
}
/**
 *
 * @export
 * @interface ProjectionResponse
 */
export interface ProjectionResponse {
  /**
   *
   * @type {string}
   * @memberof ProjectionResponse
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ProjectionResponse
   */
  ownerWorkspaceId: string;
  /**
   *
   * @type {string}
   * @memberof ProjectionResponse
   */
  type: string;
  /**
   *
   * @type {Array<Filter>}
   * @memberof ProjectionResponse
   */
  filters: Array<Filter>;
  /**
   *
   * @type {string} An ISO date string
   * @memberof ProjectionResponse
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof ProjectionResponse
   */
  customerName: string;
}
/**
 *
 * @export
 * @interface ProjectionResponseDTO
 */
export interface ProjectionResponseDTO {
  /**
   *
   * @type {string}
   * @memberof ProjectionResponseDTO
   */
  key: string;
  /**
   *
   * @type {FilterResponseDTO}
   * @memberof ProjectionResponseDTO
   */
  filter: FilterResponseDTO;
  /**
   *
   * @type {Array<ProjectionResponseOrganizationDTO>}
   * @memberof ProjectionResponseDTO
   */
  mappedTo: Array<ProjectionResponseOrganizationDTO>;
  /**
   *
   * @type {ProjectionResponseDTO}
   * @memberof ProjectionResponseDTO
   */
  mappedThrough?: ProjectionResponseDTO;
  /**
   *
   * @type {boolean}
   * @memberof ProjectionResponseDTO
   */
  active: boolean;
}
/**
 *
 * @export
 * @interface ProjectionResponseOrganizationDTO
 */
export interface ProjectionResponseOrganizationDTO {
  /**
   *
   * @type {string}
   * @memberof ProjectionResponseOrganizationDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ProjectionResponseOrganizationDTO
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof ProjectionResponseOrganizationDTO
   */
  weight: number;
}
/**
 *
 * @export
 * @interface QueryDimension
 */
export interface QueryDimension {
  /**
   *
   * @type {number}
   * @memberof QueryDimension
   */
  limit?: number;
  /**
   *
   * @type {boolean}
   * @memberof QueryDimension
   */
  includeOthers?: boolean;
  /**
   *
   * @type {string}
   * @memberof QueryDimension
   */
  arg?: string;
  /**
   *
   * @type {string}
   * @memberof QueryDimension
   */
  segment: string;
  /**
   *
   * @type {string}
   * @memberof QueryDimension
   */
  orderBy?: QueryDimensionOrderByEnum;
}

/**
 * @export
 * @enum {string}
 */

export const QueryDimensionOrderByEnum = {
  COST: "COST",
  DISCOUNT: "DISCOUNT",
  SALES: "SALES",
  CROSS_CHARGING: "CROSS_CHARGING",
  RETAIL: "RETAIL",
  QUANTITY: "QUANTITY",
} as const;
export type QueryDimensionOrderByEnum =
  typeof QueryDimensionOrderByEnum[keyof typeof QueryDimensionOrderByEnum];

/**
 *
 * @export
 * @interface QueryFilter
 */
export interface QueryFilter {
  /**
   *
   * @type {string}
   * @memberof QueryFilter
   */
  dimension: string;
  /**
   *
   * @type {Array<string>}
   * @memberof QueryFilter
   */
  filters: Array<string>;
}
/**
 *
 * @export
 * @interface QueryResponseAttribute
 */
export interface QueryResponseAttribute {
  /**
   *
   * @type {string}
   * @memberof QueryResponseAttribute
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof QueryResponseAttribute
   */
  display?: string;
}
/**
 *
 * @export
 * @interface QueryResponseCategory
 */
export interface QueryResponseCategory {
  /**
   *
   * @type {string}
   * @memberof QueryResponseCategory
   */
  category: QueryResponseCategoryCategoryEnum;
  /**
   *
   * @type {string}
   * @memberof QueryResponseCategory
   */
  collectorId?: string;
  /**
   *
   * @type {string}
   * @memberof QueryResponseCategory
   */
  display?: string;
}

/**
 * @export
 * @enum {string}
 */

export const QueryResponseCategoryCategoryEnum = {
  CF_ATTRIBUTE: "CF_ATTRIBUTE",
  BILLING_HIERARCHY_ATTRIBUTE: "BILLING_HIERARCHY_ATTRIBUTE",
  DATA_SOURCE_ATTRIBUTE: "DATA_SOURCE_ATTRIBUTE",
} as const;
export type QueryResponseCategoryCategoryEnum =
  typeof QueryResponseCategoryCategoryEnum[keyof typeof QueryResponseCategoryCategoryEnum];

/**
 *
 * @export
 * @interface QueryResponseCosts
 */
export interface QueryResponseCosts {
  /**
   *
   * @type {number}
   * @memberof QueryResponseCosts
   */
  cost?: number;
  /**
   *
   * @type {number}
   * @memberof QueryResponseCosts
   */
  sales?: number;
  /**
   *
   * @type {number}
   * @memberof QueryResponseCosts
   */
  crossCharging?: number;
  /**
   *
   * @type {number}
   * @memberof QueryResponseCosts
   */
  quantity?: number;
  /**
   *
   * @type {number}
   * @memberof QueryResponseCosts
   */
  discount?: number;
  /**
   *
   * @type {number}
   * @memberof QueryResponseCosts
   */
  list?: number;
  /**
   *
   * @type {number}
   * @memberof QueryResponseCosts
   */
  profit?: number;
}
/**
 *
 * @export
 * @interface QueryResponseDimension
 */
export interface QueryResponseDimension {
  /**
   *
   * @type {string}
   * @memberof QueryResponseDimension
   */
  segmentId: string;
  /**
   *
   * @type {string}
   * @memberof QueryResponseDimension
   */
  segmentDisplay?: string;
  /**
   *
   * @type {string}
   * @memberof QueryResponseDimension
   */
  argId: string;
  /**
   *
   * @type {string}
   * @memberof QueryResponseDimension
   */
  argDisplay?: string;
}
/**
 *
 * @export
 * @interface QueryResponseOption
 */
export interface QueryResponseOption {
  /**
   *
   * @type {string}
   * @memberof QueryResponseOption
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof QueryResponseOption
   */
  display?: string;
  /**
   *
   * @type {string}
   * @memberof QueryResponseOption
   */
  type?: string;
}
/**
 *
 * @export
 * @interface QueryResponsePoint
 */
export interface QueryResponsePoint {
  /**
   *
   * @type {string}
   * @memberof QueryResponsePoint
   */
  key: string;
  /**
   *
   * @type {string}
   * @memberof QueryResponsePoint
   */
  keyDisplay?: string;
  /**
   *
   * @type {QueryResponseCosts}
   * @memberof QueryResponsePoint
   */
  value: QueryResponseCosts;
}
/**
 *
 * @export
 * @interface QueryResponseSegment
 */
export interface QueryResponseSegment {
  /**
   *
   * @type {string}
   * @memberof QueryResponseSegment
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof QueryResponseSegment
   */
  display?: string;
}
/**
 *
 * @export
 * @interface QuerySegment
 */
export interface QuerySegment {
  /**
   *
   * @type {string}
   * @memberof QuerySegment
   */
  argId: string;
  /**
   *
   * @type {string}
   * @memberof QuerySegment
   */
  argDisplay?: string;
  /**
   *
   * @type {Array<QueryResponsePoint>}
   * @memberof QuerySegment
   */
  points: Array<QueryResponsePoint>;
}
/**
 *
 * @export
 * @interface RecentStatusResponseDTO
 */
export interface RecentStatusResponseDTO {
  /**
   *
   * @type {string}
   * @memberof RecentStatusResponseDTO
   */
  period: string;
  /**
   *
   * @type {number}
   * @memberof RecentStatusResponseDTO
   */
  actualValue: number;
  /**
   *
   * @type {string} An ISO date string
   * @memberof RecentStatusResponseDTO
   */
  triggeredOn?: string;
  /**
   *
   * @type {boolean}
   * @memberof RecentStatusResponseDTO
   */
  isTriggered: boolean;
}
/**
 *
 * @export
 * @interface ReplyToContactDTO
 */
export interface ReplyToContactDTO {
  /**
   *
   * @type {string}
   * @memberof ReplyToContactDTO
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof ReplyToContactDTO
   */
  name?: string;
}
/**
 *
 * @export
 * @interface RequestPasswordReset
 */
export interface RequestPasswordReset {
  /**
   *
   * @type {string}
   * @memberof RequestPasswordReset
   */
  email?: string;
}
/**
 *
 * @export
 * @interface RootFilterDTO
 */
export interface RootFilterDTO {
  /**
   *
   * @type {string}
   * @memberof RootFilterDTO
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof RootFilterDTO
   */
  display?: string;
}
/**
 *
 * @export
 * @interface TimedPriceAdjustment
 */
export interface TimedPriceAdjustment {
  /**
   *
   * @type {string}
   * @memberof TimedPriceAdjustment
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof TimedPriceAdjustment
   */
  adjustmentValue: number;
}
/**
 *
 * @export
 * @interface UpdateAttributeDisplayStateRequestDTO
 */
export interface UpdateAttributeDisplayStateRequestDTO {
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof UpdateAttributeDisplayStateRequestDTO
   */
  newStates: { [key: string]: string };
}

/**
 * @export
 * @enum {string}
 */

export const UpdateAttributeDisplayStateRequestDTONewStatesEnum = {
  NEW: "NEW",
  INCLUDE: "INCLUDE",
  HIDE: "HIDE",
  DELETE: "DELETE",
} as const;
export type UpdateAttributeDisplayStateRequestDTONewStatesEnum =
  typeof UpdateAttributeDisplayStateRequestDTONewStatesEnum[keyof typeof UpdateAttributeDisplayStateRequestDTONewStatesEnum];

/**
 *
 * @export
 * @interface UpdateAttributeFiltersRequestDTO
 */
export interface UpdateAttributeFiltersRequestDTO {
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof UpdateAttributeFiltersRequestDTO
   */
  newStates: { [key: string]: string };
  /**
   *
   * @type {string[]}
   * @memberof UpdateAttributeFiltersRequestDTO
   */
  includeUndefinedFor?: UpdateAttributeFiltersRequestDTOIncludeUndefinedForEnum[];
}

/**
 * @export
 * @enum {string}
 */

export const UpdateAttributeFiltersRequestDTONewStatesEnum = {
  NEW: "NEW",
  INCLUDE: "INCLUDE",
  HIDE: "HIDE",
  DELETE: "DELETE",
} as const;
export type UpdateAttributeFiltersRequestDTONewStatesEnum =
  typeof UpdateAttributeFiltersRequestDTONewStatesEnum[keyof typeof UpdateAttributeFiltersRequestDTONewStatesEnum];
/**
 * @export
 * @enum {string}
 */

export const UpdateAttributeFiltersRequestDTOIncludeUndefinedForEnum = {
  AZURE_CUSTOMER: "AZURE_CUSTOMER",
  AZURE_SUBSCRIPTION: "AZURE_SUBSCRIPTION",
  AZURE_RESOURCE_GROUP: "AZURE_RESOURCE_GROUP",
  AZURE_MANAGEMENT_GROUP: "AZURE_MANAGEMENT_GROUP",
  AZURE_TAG: "AZURE_TAG",
  AZURE_LOCATION: "AZURE_LOCATION",
  AZURE_REGION: "AZURE_REGION",
  AZURE_INSTANCE: "AZURE_INSTANCE",
  MS_RESELLER: "MS_RESELLER",
  M365_LICENSE: "M365_LICENSE",
  AWS_TAG: "AWS_TAG",
  AWS_ACCOUNT_ALIAS: "AWS_ACCOUNT_ALIAS",
  AWS_LOCATION: "AWS_LOCATION",
  AWS_INSTANCE: "AWS_INSTANCE",
  OCI_TENANT: "OCI_TENANT",
  OCI_COMPARTMENT: "OCI_COMPARTMENT",
  OCI_REGION: "OCI_REGION",
  OCI_TAG: "OCI_TAG",
  GOOGLE_PROJECT: "GOOGLE_PROJECT",
  GOOGLE_BILLING_ACCOUNT_ID: "GOOGLE_BILLING_ACCOUNT_ID",
  GOOGLE_LABEL: "GOOGLE_LABEL",
} as const;
export type UpdateAttributeFiltersRequestDTOIncludeUndefinedForEnum =
  typeof UpdateAttributeFiltersRequestDTOIncludeUndefinedForEnum[keyof typeof UpdateAttributeFiltersRequestDTOIncludeUndefinedForEnum];

/**
 *
 * @export
 * @interface UpdateBudgetRequestDTO
 */
export interface UpdateBudgetRequestDTO {
  /**
   *
   * @type {number}
   * @memberof UpdateBudgetRequestDTO
   */
  value: number;
  /**
   *
   * @type {string}
   * @memberof UpdateBudgetRequestDTO
   */
  currency: string;
}
/**
 *
 * @export
 * @interface UpdateCollectorRequestDTO
 */
export interface UpdateCollectorRequestDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateCollectorRequestDTO
   */
  name?: string;
  /**
   *
   * @type {boolean}
   * @memberof UpdateCollectorRequestDTO
   */
  isEnabled?: boolean;
}
/**
 *
 * @export
 * @interface UpdateOrganizationalUnitRequestDTO
 */
export interface UpdateOrganizationalUnitRequestDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationalUnitRequestDTO
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationalUnitRequestDTO
   */
  reference?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationalUnitRequestDTO
   */
  grossType?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationalUnitRequestDTO
   */
  basePrice?: string;
}
/**
 *
 * @export
 * @interface UpdatePriceAdjustmentRequestDTO
 */
export interface UpdatePriceAdjustmentRequestDTO {
  /**
   *
   * @type {number}
   * @memberof UpdatePriceAdjustmentRequestDTO
   */
  adjustmentValue: number;
}
/**
 *
 * @export
 * @interface UploadCsvConfigRequestDTO
 */
export interface UploadCsvConfigRequestDTO {
  /**
   *
   * @type {string}
   * @memberof UploadCsvConfigRequestDTO
   */
  delimiter: string;
  /**
   *
   * @type {string}
   * @memberof UploadCsvConfigRequestDTO
   */
  dateFormat: string;
  /**
   *
   * @type {string}
   * @memberof UploadCsvConfigRequestDTO
   */
  numberFormat: string;
  /**
   *
   * @type {boolean}
   * @memberof UploadCsvConfigRequestDTO
   */
  hasHeader: boolean;
  /**
   *
   * @type {Array<UploadCsvFieldRequestDTO>}
   * @memberof UploadCsvConfigRequestDTO
   */
  fields: Array<UploadCsvFieldRequestDTO>;
}
/**
 *
 * @export
 * @interface UploadCsvConfigResponseDTO
 */
export interface UploadCsvConfigResponseDTO {
  /**
   *
   * @type {string}
   * @memberof UploadCsvConfigResponseDTO
   */
  delimiter?: string;
  /**
   *
   * @type {string}
   * @memberof UploadCsvConfigResponseDTO
   */
  dateFormat?: string;
  /**
   *
   * @type {string}
   * @memberof UploadCsvConfigResponseDTO
   */
  numberFormat?: string;
  /**
   *
   * @type {boolean}
   * @memberof UploadCsvConfigResponseDTO
   */
  hasHeader?: boolean;
  /**
   *
   * @type {Array<UploadCsvFieldResponseDTO>}
   * @memberof UploadCsvConfigResponseDTO
   */
  fields: Array<UploadCsvFieldResponseDTO>;
}
/**
 *
 * @export
 * @interface UploadCsvFieldRequestDTO
 */
export interface UploadCsvFieldRequestDTO {
  /**
   *
   * @type {string}
   * @memberof UploadCsvFieldRequestDTO
   */
  key: string;
  /**
   *
   * @type {number}
   * @memberof UploadCsvFieldRequestDTO
   */
  index?: number;
  /**
   *
   * @type {string}
   * @memberof UploadCsvFieldRequestDTO
   */
  default?: string;
}
/**
 *
 * @export
 * @interface UploadCsvFieldResponseDTO
 */
export interface UploadCsvFieldResponseDTO {
  /**
   *
   * @type {string}
   * @memberof UploadCsvFieldResponseDTO
   */
  key: string;
  /**
   *
   * @type {string}
   * @memberof UploadCsvFieldResponseDTO
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof UploadCsvFieldResponseDTO
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof UploadCsvFieldResponseDTO
   */
  type: string;
  /**
   *
   * @type {boolean}
   * @memberof UploadCsvFieldResponseDTO
   */
  allowDefault: boolean;
  /**
   *
   * @type {number}
   * @memberof UploadCsvFieldResponseDTO
   */
  index?: number;
  /**
   *
   * @type {string}
   * @memberof UploadCsvFieldResponseDTO
   */
  default?: string;
  /**
   *
   * @type {boolean}
   * @memberof UploadCsvFieldResponseDTO
   */
  isRequired: boolean;
}
/**
 *
 * @export
 * @interface UploadCsvRequestDTO
 */
export interface UploadCsvRequestDTO {
  /**
   *
   * @type {string}
   * @memberof UploadCsvRequestDTO
   */
  secret: string;
  /**
   *
   * @type {string} An ISO date string
   * @memberof UploadCsvRequestDTO
   */
  startDate: string;
  /**
   *
   * @type {string} An ISO date string
   * @memberof UploadCsvRequestDTO
   */
  endDate: string;
  /**
   *
   * @type {string}
   * @memberof UploadCsvRequestDTO
   */
  invoiceId: string;
  /**
   *
   * @type {UploadCsvConfigRequestDTO}
   * @memberof UploadCsvRequestDTO
   */
  config: UploadCsvConfigRequestDTO;
}
/**
 *
 * @export
 * @interface UserDTO
 */
export interface UserDTO {
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  email: string;
  /**
   *
   * @type {boolean}
   * @memberof UserDTO
   */
  mfaEnabled: boolean;
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  role: UserDTORoleEnum;
  /**
   *
   * @type {string} An ISO date string
   * @memberof UserDTO
   */
  lastActive?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserDTO
   */
  allowAdminAssume?: boolean;
}

/**
 * @export
 * @enum {string}
 */

export const UserDTORoleEnum = {
  ADMIN: "ADMIN",
  READ: "READ",
  MANAGED_C_VIEW: "MANAGED_C_VIEW",
} as const;
export type UserDTORoleEnum =
  typeof UserDTORoleEnum[keyof typeof UserDTORoleEnum];

/**
 *
 * @export
 * @interface ValueCostMap
 */
export interface ValueCostMap {
  /**
   *
   * @type {number}
   * @memberof ValueCostMap
   */
  cost?: number;
  /**
   *
   * @type {number}
   * @memberof ValueCostMap
   */
  list?: number;
  /**
   *
   * @type {number}
   * @memberof ValueCostMap
   */
  crossCharging?: number;
  /**
   *
   * @type {number}
   * @memberof ValueCostMap
   */
  sales?: number;
  /**
   *
   * @type {number}
   * @memberof ValueCostMap
   */
  discount?: number;
  /**
   *
   * @type {number}
   * @memberof ValueCostMap
   */
  profit?: number;
  /**
   *
   * @type {LineItemQueryResponseDTOCurrency}
   * @memberof ValueCostMap
   */
  currency?: LineItemQueryResponseDTOCurrency;
}
/**
 *
 * @export
 * @interface WorkspaceDTO
 */
export interface WorkspaceDTO {
  /**
   *
   * @type {string}
   * @memberof WorkspaceDTO
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof WorkspaceDTO
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof WorkspaceDTO
   */
  subscriptionType: string;
  /**
   *
   * @type {string}
   * @memberof WorkspaceDTO
   */
  enabledFeatures: string;
  /**
   *
   * @type {string}
   * @memberof WorkspaceDTO
   */
  workspaceId?: string;
}

/* tslint:disable */
/* eslint-disable */
/**
 * 
 * 
 *
 * 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    BatchProjectionResponseDTO,
    CreateBatchProjectionRequestDTO,
    CreateOrganizationsForCollectorRequestParams,
    ListOptionsForBatchProjectionsRequestDTO,
} from '../models';

export interface BatchProjectionsRequest {
    createBatchProjectionRequestDTO: Array<CreateBatchProjectionRequestDTO>;
}

export interface CreateAllForCollectorRequest {
    collectorId: string;
    createOrganizationsForCollectorRequestParams: CreateOrganizationsForCollectorRequestParams;
}

export interface ListOptionsForBatchProjectionsRequest {
    listOptionsForBatchProjectionsRequestDTO: Array<ListOptionsForBatchProjectionsRequestDTO>;
}

/**
 * 
 */
export class BatchProjectionControllerApi extends runtime.BaseAPI {

    /**
     */
    async batchProjectionsRaw(requestParameters: BatchProjectionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.createBatchProjectionRequestDTO === null || requestParameters.createBatchProjectionRequestDTO === undefined) {
            throw new runtime.RequiredError('createBatchProjectionRequestDTO','Required parameter requestParameters.createBatchProjectionRequestDTO was null or undefined when calling batchProjections.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/batch/projections`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createBatchProjectionRequestDTO,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async batchProjections(requestParameters: BatchProjectionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.batchProjectionsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createAllForCollectorRaw(requestParameters: CreateAllForCollectorRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.collectorId === null || requestParameters.collectorId === undefined) {
            throw new runtime.RequiredError('collectorId','Required parameter requestParameters.collectorId was null or undefined when calling createAllForCollector.');
        }

        if (requestParameters.createOrganizationsForCollectorRequestParams === null || requestParameters.createOrganizationsForCollectorRequestParams === undefined) {
            throw new runtime.RequiredError('createOrganizationsForCollectorRequestParams','Required parameter requestParameters.createOrganizationsForCollectorRequestParams was null or undefined when calling createAllForCollector.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/batch/projections/auto/{collectorId}`.replace(`{${"collectorId"}}`, encodeURIComponent(String(requestParameters.collectorId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createOrganizationsForCollectorRequestParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createAllForCollector(requestParameters: CreateAllForCollectorRequest, initOverrides?: RequestInit): Promise<void> {
        await this.createAllForCollectorRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteUnusedAttributesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/batch/projections/cleanup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteUnusedAttributes(initOverrides?: RequestInit): Promise<void> {
        await this.deleteUnusedAttributesRaw(initOverrides);
    }

    /**
     */
    async getOptionsForBatchProjectionsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<BatchProjectionResponseDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/batch/projections`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async getOptionsForBatchProjections(initOverrides?: RequestInit): Promise<BatchProjectionResponseDTO> {
        const response = await this.getOptionsForBatchProjectionsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async listOptionsForBatchProjectionsRaw(requestParameters: ListOptionsForBatchProjectionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BatchProjectionResponseDTO>> {
        if (requestParameters.listOptionsForBatchProjectionsRequestDTO === null || requestParameters.listOptionsForBatchProjectionsRequestDTO === undefined) {
            throw new runtime.RequiredError('listOptionsForBatchProjectionsRequestDTO','Required parameter requestParameters.listOptionsForBatchProjectionsRequestDTO was null or undefined when calling listOptionsForBatchProjections.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/batch/projections/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.listOptionsForBatchProjectionsRequestDTO,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async listOptionsForBatchProjections(requestParameters: ListOptionsForBatchProjectionsRequest, initOverrides?: RequestInit): Promise<BatchProjectionResponseDTO> {
        const response = await this.listOptionsForBatchProjectionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/* tslint:disable */
/* eslint-disable */
/**
 * 
 * 
 *
 * 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    EnableMFADTO,
    ForceMFADTO,
    GetMFASecretDTO,
} from '../models';

export interface CompleteLoginRequest {
    code: string;
}

export interface DisableForcedMFARequest {
    forceMFADTO: ForceMFADTO;
}

export interface EnableForcedMFARequest {
    forceMFADTO: ForceMFADTO;
}

export interface EnableMFARequest {
    enableMFADTO: EnableMFADTO;
}

export interface IsWorkspaceMFAForcedRequest {
    forceMFADTO: ForceMFADTO;
}

export interface ResetMFARequest {
    email: string;
}

/**
 * 
 */
export class MfaControllerApi extends runtime.BaseAPI {

    /**
     */
    async completeLoginRaw(requestParameters: CompleteLoginRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling completeLogin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/mfa/verify/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async completeLogin(requestParameters: CompleteLoginRequest, initOverrides?: RequestInit): Promise<void> {
        await this.completeLoginRaw(requestParameters, initOverrides);
    }

    /**
     */
    async disableForcedMFARaw(requestParameters: DisableForcedMFARequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.forceMFADTO === null || requestParameters.forceMFADTO === undefined) {
            throw new runtime.RequiredError('forceMFADTO','Required parameter requestParameters.forceMFADTO was null or undefined when calling disableForcedMFA.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/mfa/force/disable`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.forceMFADTO,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async disableForcedMFA(requestParameters: DisableForcedMFARequest, initOverrides?: RequestInit): Promise<void> {
        await this.disableForcedMFARaw(requestParameters, initOverrides);
    }

    /**
     */
    async disableMFARaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/mfa/disable`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async disableMFA(initOverrides?: RequestInit): Promise<void> {
        await this.disableMFARaw(initOverrides);
    }

    /**
     */
    async enableForcedMFARaw(requestParameters: EnableForcedMFARequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.forceMFADTO === null || requestParameters.forceMFADTO === undefined) {
            throw new runtime.RequiredError('forceMFADTO','Required parameter requestParameters.forceMFADTO was null or undefined when calling enableForcedMFA.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/mfa/force/enable`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.forceMFADTO,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async enableForcedMFA(requestParameters: EnableForcedMFARequest, initOverrides?: RequestInit): Promise<void> {
        await this.enableForcedMFARaw(requestParameters, initOverrides);
    }

    /**
     */
    async enableMFARaw(requestParameters: EnableMFARequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.enableMFADTO === null || requestParameters.enableMFADTO === undefined) {
            throw new runtime.RequiredError('enableMFADTO','Required parameter requestParameters.enableMFADTO was null or undefined when calling enableMFA.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/mfa/enable`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.enableMFADTO,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async enableMFA(requestParameters: EnableMFARequest, initOverrides?: RequestInit): Promise<void> {
        await this.enableMFARaw(requestParameters, initOverrides);
    }

    /**
     */
    async getNewMFASecretRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetMFASecretDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/mfa/request`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async getNewMFASecret(initOverrides?: RequestInit): Promise<GetMFASecretDTO> {
        const response = await this.getNewMFASecretRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async isMFAForcedRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/mfa/force`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async isMFAForced(initOverrides?: RequestInit): Promise<string> {
        const response = await this.isMFAForcedRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async isWorkspaceMFAForcedRaw(requestParameters: IsWorkspaceMFAForcedRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.forceMFADTO === null || requestParameters.forceMFADTO === undefined) {
            throw new runtime.RequiredError('forceMFADTO','Required parameter requestParameters.forceMFADTO was null or undefined when calling isWorkspaceMFAForced.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/mfa/force/workspace`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.forceMFADTO,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async isWorkspaceMFAForced(requestParameters: IsWorkspaceMFAForcedRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.isWorkspaceMFAForcedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async resetMFARaw(requestParameters: ResetMFARequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling resetMFA.');
        }

        const queryParameters: any = {};

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/mfa/reset`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async resetMFA(requestParameters: ResetMFARequest, initOverrides?: RequestInit): Promise<void> {
        await this.resetMFARaw(requestParameters, initOverrides);
    }

}

import { Redirect, Route, Switch } from "react-router-dom";
import { DashboardPage } from "../pages/dashboard/DashboardPage";
import LoginPage from "../pages/login/LoginPage";
import AppLayout from "./AppLayout";
import { OrganizationalUnitPage } from "../pages/organizationalunit/OrganizationalUnitPage";
import { DataSourcesPage } from "../pages/datasources/DataSourcesPage";
import { InvoicesPage } from "../pages/invoices/InvoicesPage";
import { NoMatchPage } from "./NoMatchPage";
import { DataSourceDetailPage } from "../pages/datasources/DataSourceDetailPage";
import { BillingOverviewPage, getBillingPageTitle } from "../pages/billing/BillingOverviewPage";
import { AccountPage } from "../pages/account/AccountPage";
import { InformationPage } from "../pages/information/InformationPage";
import { UserManagementPage } from "../pages/usermanagement/UserManagementPage";
import { WelcomePage } from "../pages/welcome/WelcomePage";
import { OrganizationPage } from "../pages/organizationalunit/organization/OrganizationPage";
import { CARedirectHandler } from "../pages/datasources/forms/redirect/CARedirectHandler";
import { NewDashPageProvider } from "../pages/newdash/NewDashPageProvider";
import { SelectBillingCycleProvider } from "../providers/billing-cycle.provider";
import { AdminRoutes } from "../admin/AdminRoutes";
import { useFeatureToggle } from "../hooks/ft.hooks";
import { MessagePage } from "./MessagePage";
import Typography from "@material-ui/core/Typography";
import NotManagedPortalGuardedRoute from "../routeGuards/ManagedPortalGuardedRoute";
import { DownloadPage } from "../pages/downloads/Downloads";
import { FEATURES } from "../app.config";

export function AppRouter() {
  const maintenance = useFeatureToggle(FEATURES.maintenance);

  if (maintenance) {
    return (
      <MessagePage>
        <Typography variant="h5">Maintenance</Typography>
        <Typography>
          We are currently performing maintenance on the C-Facts application, please check back soon
        </Typography>
      </MessagePage>
    );
  }

  return (
    <Switch>
      <Route
        exact
        path="/information"
        render={(props) => (
          <AppLayout title="Information">
            <InformationPage />
          </AppLayout>
        )}
      />
      <Route
        exact
        path="/dashboard"
        render={(props) => (
          <AppLayout title={<>Dashboard</>}>
            <SelectBillingCycleProvider>
              <DashboardPage />
            </SelectBillingCycleProvider>
          </AppLayout>
        )}
      />
      <Route
        exact
        path="/analytics"
        render={(props) => (
          <AppLayout title={"Analytics"}>
            <NewDashPageProvider />
          </AppLayout>
        )}
      />
      <Route
        exact
        path="/billing"
        render={(props) => (
          <AppLayout title={getBillingPageTitle()}>
            <SelectBillingCycleProvider>
              <BillingOverviewPage />
            </SelectBillingCycleProvider>
          </AppLayout>
        )}
      />
      <NotManagedPortalGuardedRoute
        exact
        path="/invoices"
        renderComponent={() => (
          <AppLayout title={"Cloud Purchasing"}>
            <SelectBillingCycleProvider>
              <InvoicesPage />
            </SelectBillingCycleProvider>
          </AppLayout>
        )}
      />
      <NotManagedPortalGuardedRoute
        exact
        path="/datasources"
        renderComponent={() => (
          <AppLayout title="Data Sources">
            <DataSourcesPage />
          </AppLayout>
        )}
      />
      <NotManagedPortalGuardedRoute
        exact
        path="/datasources/:collectorId"
        renderComponent={(props: { computedMatch: { params: { collectorId: string } } }) => (
          <AppLayout title="Data Source - Detail">
            <DataSourceDetailPage collectorId={props.computedMatch.params.collectorId} />
          </AppLayout>
        )}
      />
      <Route
        exact
        path="/organizational-unit"
        render={(props) => (
          <AppLayout title={"Organizations"}>
            <OrganizationalUnitPage />
          </AppLayout>
        )}
      />
      <Route
        exact
        path="/organizational-unit/:filterType"
        render={(props) => (
          <AppLayout title={"Organizations"}>
            <OrganizationalUnitPage />
          </AppLayout>
        )}
      />
      <Route
        exact
        path="/organizational-unit/unit/:unitId"
        render={(props) => (
          <AppLayout title="Organization details">
            <OrganizationPage organizationalUnitId={props.match.params.unitId} />
          </AppLayout>
        )}
      />
      <NotManagedPortalGuardedRoute
        path="/user-management"
        renderComponent={() => (
          <AppLayout title="User Management">
            <UserManagementPage />
          </AppLayout>
        )}
      />
      <Route
        path="/account"
        render={(props) => (
          <AppLayout title="My Account">
            <AccountPage />
          </AppLayout>
        )}
      />
      <Route
        path={"/downloads/:id"}
        render={(props) => (
          <AppLayout title="Downloads">
            <DownloadPage id={props.match.params.id} />
          </AppLayout>
        )}
      />
      <Route
        exact
        path="/welcome"
        render={(props) => (
          <AppLayout title="Welcome">
            <WelcomePage />
          </AppLayout>
        )}
      />
      <Route exact path="/azureconsent" render={(props) => <CARedirectHandler />} />
      <Route path="/login" component={LoginPage} />
      <Route path="/admin" component={AdminRoutes} />
      <Redirect exact from="/" to="/login" />
      <Route component={NoMatchPage} />
    </Switch>
  );
}

import { DateTime } from "luxon";
import { CFCard } from "../../components/layout/CFContainer";
import { Money } from "../../components/Money";
import { CollectorConfigResponseDTO, CollectorResponseDTO } from "../../open-api";
import { useMonthlyOverview } from "../../clients/dashboard.client";
import { yearMonth } from "../../utils/functional/date.utils";
import { collectorType, getIncludeAllData } from "../../utils/collector.utils";
import { useFeatureToggle } from "../../hooks/ft.hooks";
import { FEATURES } from "../../app.config";

interface CollectorStatsProps {
  collector: CollectorResponseDTO;
  collectorId: string;
  collectorConfig: CollectorConfigResponseDTO | null;
}

export const CollectorStats = ({ collector, collectorId, collectorConfig }: CollectorStatsProps) => {
  const [monthlyOverview, , loading] = useMonthlyOverview();
  const useFilterAttributes = useFeatureToggle(FEATURES.filterAttributes);
  const total = monthlyOverview?.filter((it) => it.collectorId === collectorId).reduce((sum, it) => it.cost + sum, 0);
  const currentMonth = monthlyOverview
    ?.filter((it) => it.collectorId === collectorId && it.billingMonth === yearMonth(DateTime.local()))
    .reduce((sum, it) => it.cost + sum, 0);

  return (
    <CFCard header="Stats">
      <p>
        Collector type: <strong>{collectorType(collector)}</strong>
      </p>
      {useFilterAttributes && (
        <p>
          Collector includes all data: <strong>{getIncludeAllData(collectorConfig) ? "YES" : "NO"}</strong>
        </p>
      )}
      <p>
        Total cost for line items processed:{" "}
        <strong>
          <Money money={total} isLoading={loading} />
        </strong>
      </p>
      <p>
        Cost over {DateTime.local().toFormat("MMMM y")} so far:{" "}
        <strong>
          <Money money={currentMonth} isLoading={loading} />
        </strong>
      </p>
    </CFCard>
  );
};

import MaterialTable, { Column } from "@material-table/core";
import { tableIcons } from "../../theme/TableIcons";
import { PortalChips } from "./components/PortalChips";
import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { DateTime } from "luxon";
import { Money } from "../../components/Money";
import { green, grey, red } from "@material-ui/core/colors";
import { formattedCurrency } from "../../utils/localization.utils";
import { profitLabel } from "../../mappers/organization.mapper";
import { AdjustmentType } from "../../utils/functional/adjustments.utils";
import { OrganizationalUnitResponseDTO, PortalResponseDTO, QueryResponsePoint } from "../../open-api";
import { getOrganizationUnitLabel } from "../../utils/organization.utils";
import { useAggregateQuery, useMostRecentPeriodsFilter } from "../../clients/query.client";
import { GROUP_SEGMENT } from "../newdash/model/GroupSegment";
import { ConditionalRender } from "../../components/ConditionalRender";
import { useIsManagedCView, WorkspaceRole } from "../../hooks/auth.hooks";

interface CustomersListProps {
  organizationalUnits: OrganizationalUnitResponseDTO[];
  onDelete: (organizationalUnit: OrganizationalUnitResponseDTO) => void;
  onClick: (organizationalUnit: OrganizationalUnitResponseDTO) => void;
  type?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      margin: theme.spacing(0.5),
    },
    noMaxWidth: {
      maxWidth: 500,
    },
    trend: {
      fontWeight: "bold",
    },
    up: {
      color: green["400"],
      fontColor: green["400"],
    },
    down: {
      color: red["400"],
      fontColor: red["400"],
    },
    small: {
      fontSize: 13,
      color: grey["400"],
    },
  })
);

const ProfitCard = ({
  lineItem,
  organization,
}: {
  lineItem: QueryResponsePoint | undefined;
  organization: OrganizationalUnitResponseDTO;
}) => {
  const classes = useStyles();

  const profit =
    organization.grossType?.toLowerCase() === AdjustmentType.MARGIN
      ? (lineItem?.value?.profit ?? 0) / (lineItem?.value?.sales ?? lineItem?.value?.crossCharging ?? 0)
      : (lineItem?.value?.sales ?? lineItem?.value?.crossCharging ?? 0) / (lineItem?.value?.cost ?? 0) - 1;
  let trendClass = "";
  let displayProfitLabel = "";

  if (profit >= 0) {
    trendClass = classes.up;
    displayProfitLabel = `${formattedCurrency(profit * 100, "", 1)}% ${profitLabel(organization)}`;
  } else if (profit < 0) {
    trendClass = classes.down;
    displayProfitLabel = `${formattedCurrency(profit * 100, "", 1)}% ${profitLabel(organization)}`;
  }

  return <Typography className={[trendClass, classes.trend].join(" ")}>{displayProfitLabel}</Typography>;
};

export function OrganizationalUnitList({ organizationalUnits, onClick, type }: CustomersListProps) {
  const filter = useMostRecentPeriodsFilter(1);
  const [lineItems] = useAggregateQuery(GROUP_SEGMENT.CF_ORGANIZATION.id, GROUP_SEGMENT.TOTAL.id, [...filter]);

  const classes = useStyles();

  const [, unitPlural] = getOrganizationUnitLabel(type ?? "INTERNAL");

  const items = lineItems && lineItems[0].arguments;

  const generalColumns: Column<OrganizationalUnitResponseDTO>[] = [
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Reference",
      field: "reference",
    },
    {
      title: "C-View Summary",
      cellStyle: {
        width: "40%",
      },
      render: (rowData: OrganizationalUnitResponseDTO) => {
        const lineItem = items?.find((i) => i.argId === rowData.id)?.points[0];

        return (
          <Grid container direction="column" justify="flex-start" alignItems="flex-start">
            <ConditionalRender hasRole={[WorkspaceRole.ADMIN, WorkspaceRole.READ]}>
              <ProfitCard lineItem={lineItem} organization={rowData} />
            </ConditionalRender>
            <Grid item>
              {Object.keys(lineItem?.value ?? {}).length === 0 && <>No associated line items</>}
              {lineItem?.value?.cost && (
                <>
                  Cost: <Money money={lineItem?.value?.cost} />
                </>
              )}
              {lineItem?.value?.sales && (
                <>
                  {" "}
                  / sales: <Money money={lineItem?.value?.sales} />
                </>
              )}
              {lineItem?.value?.crossCharging && (
                <>
                  {" "}
                  / cross-charging: <Money money={lineItem?.value?.crossCharging} />
                </>
              )}
            </Grid>
            <Grid item className={classes.small}>
              Over {DateTime.local().toFormat("MMMM y")} so far
            </Grid>
          </Grid>
        );
      },
    },
  ];

  const cViewColumn: Column<OrganizationalUnitResponseDTO> = {
    title: "C-View Shared",
    render: (rowData: OrganizationalUnitResponseDTO) => {
      const allPortals: PortalResponseDTO[] = [
        ...(rowData.portals ?? []),
        ...(rowData.children?.flatMap((it) => it.portals ?? []) ?? []),
        ...(rowData.managedPortals ?? []),
        ...(rowData.children?.flatMap((it) => it.managedPortals ?? []) ?? []),
      ];

      return (
        <>
          <PortalChips portals={allPortals} />
        </>
      );
    },
  };

  return (
    <MaterialTable
      icons={tableIcons}
      title={unitPlural}
      columns={useIsManagedCView() ? generalColumns : generalColumns.concat(cViewColumn)}
      data={organizationalUnits}
      options={{
        draggable: false,
        pageSize: 10,
        actionsColumnIndex: -1,
        headerStyle: {
          fontWeight: "bold",
          backgroundColor: "rgba(255,255,255,0.06)",
          fontSize: 15,
          width: "20%",
        },
        rowStyle: {
          verticalAlign: "top",
        },
      }}
      onRowClick={(event, rowData, togglePanel) => onClick(Array.isArray(rowData) ? rowData[0] : rowData || null)}
    />
  );
}

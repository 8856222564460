/* tslint:disable */
/* eslint-disable */
/**
 * 
 * 
 *
 * 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    CollectorPriceAdjustmentResponseDTO,
    CreatePriceAdjustmentRequestDTO,
    TimedPriceAdjustment,
    UpdatePriceAdjustmentRequestDTO,
} from '../models';

export interface CreateCollectorAdjustmentRequest {
    collectorId: string;
    createPriceAdjustmentRequestDTO: CreatePriceAdjustmentRequestDTO;
}

export interface DeleteCollectorAdjustmentRequest {
    collectorId: string;
    id: string;
}

export interface GetCollectorAdjustmentsRequest {
    collectorId: string;
    includeAvailable?: boolean;
}

export interface UpdateCollectorAdjustmentRequest {
    collectorId: string;
    id: string;
    updatePriceAdjustmentRequestDTO: UpdatePriceAdjustmentRequestDTO;
}

/**
 * 
 */
export class DataSourcePriceAdjustmentsApi extends runtime.BaseAPI {

    /**
     * Create a price adjustment for data source
     */
    async createCollectorAdjustmentRaw(requestParameters: CreateCollectorAdjustmentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TimedPriceAdjustment>> {
        if (requestParameters.collectorId === null || requestParameters.collectorId === undefined) {
            throw new runtime.RequiredError('collectorId','Required parameter requestParameters.collectorId was null or undefined when calling createCollectorAdjustment.');
        }

        if (requestParameters.createPriceAdjustmentRequestDTO === null || requestParameters.createPriceAdjustmentRequestDTO === undefined) {
            throw new runtime.RequiredError('createPriceAdjustmentRequestDTO','Required parameter requestParameters.createPriceAdjustmentRequestDTO was null or undefined when calling createCollectorAdjustment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/collectors/{collectorId}/price-adjustments`.replace(`{${"collectorId"}}`, encodeURIComponent(String(requestParameters.collectorId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createPriceAdjustmentRequestDTO,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Create a price adjustment for data source
     */
    async createCollectorAdjustment(requestParameters: CreateCollectorAdjustmentRequest, initOverrides?: RequestInit): Promise<TimedPriceAdjustment> {
        const response = await this.createCollectorAdjustmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a price adjustment for data source
     */
    async deleteCollectorAdjustmentRaw(requestParameters: DeleteCollectorAdjustmentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.collectorId === null || requestParameters.collectorId === undefined) {
            throw new runtime.RequiredError('collectorId','Required parameter requestParameters.collectorId was null or undefined when calling deleteCollectorAdjustment.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCollectorAdjustment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/collectors/{collectorId}/price-adjustments/{id}`.replace(`{${"collectorId"}}`, encodeURIComponent(String(requestParameters.collectorId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a price adjustment for data source
     */
    async deleteCollectorAdjustment(requestParameters: DeleteCollectorAdjustmentRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteCollectorAdjustmentRaw(requestParameters, initOverrides);
    }

    /**
     * View price adjustments for data source
     */
    async getCollectorAdjustmentsRaw(requestParameters: GetCollectorAdjustmentsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<CollectorPriceAdjustmentResponseDTO>>> {
        if (requestParameters.collectorId === null || requestParameters.collectorId === undefined) {
            throw new runtime.RequiredError('collectorId','Required parameter requestParameters.collectorId was null or undefined when calling getCollectorAdjustments.');
        }

        const queryParameters: any = {};

        if (requestParameters.includeAvailable !== undefined) {
            queryParameters['includeAvailable'] = requestParameters.includeAvailable;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/collectors/{collectorId}/price-adjustments`.replace(`{${"collectorId"}}`, encodeURIComponent(String(requestParameters.collectorId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * View price adjustments for data source
     */
    async getCollectorAdjustments(requestParameters: GetCollectorAdjustmentsRequest, initOverrides?: RequestInit): Promise<Array<CollectorPriceAdjustmentResponseDTO>> {
        const response = await this.getCollectorAdjustmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a price adjustment for data source
     */
    async updateCollectorAdjustmentRaw(requestParameters: UpdateCollectorAdjustmentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.collectorId === null || requestParameters.collectorId === undefined) {
            throw new runtime.RequiredError('collectorId','Required parameter requestParameters.collectorId was null or undefined when calling updateCollectorAdjustment.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCollectorAdjustment.');
        }

        if (requestParameters.updatePriceAdjustmentRequestDTO === null || requestParameters.updatePriceAdjustmentRequestDTO === undefined) {
            throw new runtime.RequiredError('updatePriceAdjustmentRequestDTO','Required parameter requestParameters.updatePriceAdjustmentRequestDTO was null or undefined when calling updateCollectorAdjustment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/collectors/{collectorId}/price-adjustments/{id}`.replace(`{${"collectorId"}}`, encodeURIComponent(String(requestParameters.collectorId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.updatePriceAdjustmentRequestDTO,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a price adjustment for data source
     */
    async updateCollectorAdjustment(requestParameters: UpdateCollectorAdjustmentRequest, initOverrides?: RequestInit): Promise<void> {
        await this.updateCollectorAdjustmentRaw(requestParameters, initOverrides);
    }

}

/* tslint:disable */
/* eslint-disable */
/**
 * 
 * 
 *
 * 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    AzureSsoConfigRequestDTO,
    AzureSsoConfigResponseDTO,
} from '../models';

export interface DeleteConfigRequest {
    configId: string;
}

export interface PutConfigRequest {
    azureSsoConfigRequestDTO: AzureSsoConfigRequestDTO;
}

/**
 * 
 */
export class AzureSsoConfigControllerApi extends runtime.BaseAPI {

    /**
     */
    async deleteConfigRaw(requestParameters: DeleteConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling deleteConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/sso/config/{configId}`.replace(`{${"configId"}}`, encodeURIComponent(String(requestParameters.configId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteConfig(requestParameters: DeleteConfigRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteConfigRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getConfigRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AzureSsoConfigResponseDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/sso/config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async getConfig(initOverrides?: RequestInit): Promise<AzureSsoConfigResponseDTO> {
        const response = await this.getConfigRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async putConfigRaw(requestParameters: PutConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AzureSsoConfigResponseDTO>> {
        if (requestParameters.azureSsoConfigRequestDTO === null || requestParameters.azureSsoConfigRequestDTO === undefined) {
            throw new runtime.RequiredError('azureSsoConfigRequestDTO','Required parameter requestParameters.azureSsoConfigRequestDTO was null or undefined when calling putConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/sso/config`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.azureSsoConfigRequestDTO,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async putConfig(requestParameters: PutConfigRequest, initOverrides?: RequestInit): Promise<AzureSsoConfigResponseDTO> {
        const response = await this.putConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

import { useDataClient } from "../hooks/client.hooks";
import { useCurrency } from "../hooks/config.hooks";
import { yearMonth } from "../utils/functional/date.utils";
import { DateTime } from "luxon";
import { range } from "../utils/functional/range.utils";
import { LineItemFilter } from "../services/lineitem.service";
import { useMonthlyBillingPeriod, useSelectedBillingPeriods } from "../hooks/billing-periods.hooks";
import { GrossTypeText } from "../texts/gross-type.text";
import { NestedLineItemDTOV3 } from "../open-api";
import { getRequest } from "../utils/api.utils";

export const useLineItemsForInvoice = (invoiceId: string) =>
  useDataClient<NestedLineItemDTOV3[]>(`/api/lineitems/query/invoice/${invoiceId}`);

export const useLineItemsForBillingPeriod = (
  billingPeriod: string,
  organizationId: string | null,
  collectorId?: string
) => {
  const url = useLineItemsForBillingPeriodURL(billingPeriod, organizationId, collectorId);
  return useDataClient<NestedLineItemDTOV3[]>(url);
};

export const useLineItemsForBillingPeriodURL = (
  billingPeriod: string,
  organizationId: string | null,
  collectorId?: string
) => {
  const currency = useCurrency();
  const monthlyBillingPeriod = useMonthlyBillingPeriod();

  const cycleTypeKey = monthlyBillingPeriod ? "month" : "period";

  return `/api/lineitems/query/${cycleTypeKey}/${billingPeriod}?currency=${currency}${
    organizationId ? `&orgId=${organizationId}` : ""
  }${collectorId ? `&collectorId=${collectorId}` : ""}`;
};

export const useLineItemsForMonth = (month: string, orgId?: string, depId?: string) => {
  const currency = useCurrency();
  const filterString = `${orgId ? `&orgId=${orgId}` : ""}${depId ? `&depId=${depId}` : ""}`;
  return useDataClient<NestedLineItemDTOV3[]>(
    `/api/lineitems/query/month/${month}?currency=${currency}${filterString}`
  );
};

export const useLineItemDownloadUrl = (billingPeriod: string, orgId: string, collectorId?: string) => {
  const monthlyBillingPeriod = useMonthlyBillingPeriod();
  const currency = useCurrency();

  const bmFilter = monthlyBillingPeriod ? DIMENSIONS.BILLING_MONTH : DIMENSIONS.INVOICE_PERIOD;
  const filters = {
    [bmFilter]: [billingPeriod],
  };
  if (orgId) {
    filters[DIMENSIONS.UNIT_ID] = [orgId];
  }
  if (collectorId) {
    filters[DIMENSIONS.DATASOURCE] = [collectorId];
  }
  return `/api/lineitems${encodeURI(getLineItemQueryParamString([], currency, filters))}`;
};

export const useNMostRecentPeriods = (periods: number = 3, forceMonthly: boolean = true) => {
  const selected = useSelectedBillingPeriods();

  return !forceMonthly && !!selected
    ? // If selected pick N most recent billing periods
      selected.periods
        .slice(-Math.min(selected.periods.length, periods))
        .map((it) => `${it.from}|${it.to}`)
        .reverse()
    : // Pick N most recent yearmonths
      range(periods).map((i) => yearMonth(DateTime.local().minus({ month: i })));
};

export const applyDiscounts = () => getRequest(`/api/lineitems/update-discounts`);

/**
 * Returns the query param string for the given dimensions, currencies and filters.
 * @param dimensions
 * @param currency
 * @param filter
 */
export const getLineItemQueryParamString = (dimensions?: string[], currency?: string, filter?: LineItemFilter) => {
  const params = new URLSearchParams();
  if (dimensions && dimensions.length > 0) {
    params.set("dimensions", dimensions.join(","));
  }
  if (currency) {
    params.set("currency", currency);
  }

  if (filter) {
    (Object.keys(filter) as (keyof typeof DIMENSIONS)[])
      .filter((dimension) => !!filter[dimension])
      .forEach((dimension) => {
        // @ts-ignore
        params.set(`filter.${dimension}`, filter[dimension].join(","));
      });
  }

  const queryStr = decodeURIComponent(params.toString());
  if (queryStr.length > 0) {
    return `?${queryStr}`;
  }

  return "";
};

export type ValueKey = "cost" | "list" | "discount" | "sales" | "crossCharging" | "profit";

export const displayValueKey = (key: ValueKey) => {
  switch (key) {
    case "cost":
      return GrossTypeText.invoicePrice.title;
    case "list":
      return GrossTypeText.retailPrice.title;
    case "discount":
      return GrossTypeText.priceAfterDiscount.title;
    case "crossCharging":
      return GrossTypeText.crossCharging.title;
    case "sales":
      return GrossTypeText.sales.title;
    case "profit":
      return GrossTypeText.profit.title;
  }
};

export const displayValueKeyDescription = (key: ValueKey) => {
  switch (key) {
    case "cost":
      return GrossTypeText.invoicePrice.description;
    case "list":
      return GrossTypeText.retailPrice.description;
    case "discount":
      return GrossTypeText.priceAfterDiscount.description;
    case "profit":
      return GrossTypeText.profit.description;
    case "crossCharging":
      return GrossTypeText.crossCharging.description;
    case "sales":
      return GrossTypeText.sales.description;
  }
};

export const DIMENSIONS = {
  TYPE: "TYPE",
  ORGANIZATION: "ORGANIZATION",
  DEPARTMENT: "DEPARTMENT",
  CUSTOMER_TAG: "CUSTOMER_TAG",
  ORGANIZATION_ATTRIBUTE: "ORGANIZATION_ATTRIBUTE",
  APPLICATION_TAG: "APPLICATION_TAG",
  COST_CENTER_ATTRIBUTE: "COST_CENTER_ATTRIBUTE",
  LOCATION: "LOCATION",
  COST_TYPE: "COST_TYPE",
  SERVICE: "SERVICE",
  DETAIL: "DETAIL",
  CLOUD: "CLOUD",
  INVOICE: "INVOICE",
  INVOICE_PERIOD: "INVOICE_PERIOD",
  BILLING_PERIOD: "BILLING_PERIOD",
  DATASOURCE: "DATASOURCE",
  BILLING_MONTH: "BILLING_MONTH",
  ROOT: "ROOT",

  /* Filter only */
  UNIT_ID: "UNIT_ID",
} as const;

export const LINE_ITEM_DEFAULTS = {
  TYPE: {
    CUSTOMER: "CUSTOMER",
    INTERNAL: "INTERNAL",
    UNMAPPED: "UNMAPPED",
  },
  UNIT_TYPE: {
    ROOT: "ROOT",
    DEPARTMENT: "DEPARTMENT",
    APPLICATION: "APPLICATION",
    PROJECT: "PROJECT",
  },
  COST_TYPE: {
    CLOUD_USAGE: "Usage",
    LICENSE: "License",
    OTHER: "Other",
    UNKNOWN: "Unknown",
  },
};

import MaterialTable, { MaterialTableProps } from "@material-table/core";
import { useDataClientPromise } from "../../../hooks/client.hooks";
import { useServices } from "../../../services/context.service";

const DiscountsTable = () => {
  const discountService = useServices().discount;

  const [discountsList] = useDataClientPromise(discountService.getDiscounts, []);

  const state = getTableProps(discountsList || []);

  return (
    <MaterialTable
      title={"Discounts"}
      {...state}
      options={{ draggable: false, pageSize: 50, actionsColumnIndex: -1 }}
    />
  );
};

const getTableProps = (data: any): MaterialTableProps<any> => {
  return {
    columns: [
      {
        title: "Product ID",
        field: "productId",
      },
      {
        title: "Discount",
        field: "discount",
      },
      {
        title: "Start date",
        field: "startDate",
        render: (val) => val.startDate.toString(),
      },
      {
        title: "End date",
        field: "endDate",
        render: (val) => val.endDate?.toString() || "Not set",
      },
      // {
      //   title: "Workspace ID",
      //   field: "workspaceId",
      // }, // TODO check with Jordy if this is needed in FE
    ],
    data,
  };
};

export default DiscountsTable;

import { DiscountResponseDTO } from "../open-api";
import { API } from "./context.service";

export interface DiscountService {
  getDiscounts(): Promise<Array<DiscountResponseDTO>>;
}

export class LiveDiscountService implements DiscountService {
  constructor(private api: API) {}

  getDiscounts = (): Promise<Array<DiscountResponseDTO>> => {
    return this.api.discount.getDiscounts();
  };
}

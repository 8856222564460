import { useDashFilters, useDateRange } from "../selectors/dashboard.selector";
import { GroupSegment } from "./GroupSegment";
import { DataClientResponse, useDataClientPromise } from "../../../hooks/client.hooks";
import { useServices } from "../../../services/context.service";
import { toBillingMonths } from "./DateRange";
import { LineItemDisplay } from "./ChartConfig";
import { LineItemQueryResponseDTO, QueryDimensionOrderByEnum, QueryFilter } from "../../../open-api";
import { escapeHtml } from "../../../utils/dom";
import { useCurrency } from "../../../hooks/config.hooks";
import { useDashboardShouldLoad, useSetDashboardMigrationError } from "../NewDashPageProvider";
import { GroupSegmentFilter } from "./GroupSegmentFilter";
import { DIMENSIONS } from "../../../clients/lineitems.client";

export function useAggregatedLineItemsForDashboard(
  segment: GroupSegment,
  argument?: GroupSegment,
  limit?: number,
  includeOthers?: boolean,
  orderBy?: QueryDimensionOrderByEnum
): DataClientResponse<LineItemQueryResponseDTO[]> {
  const queryService = useServices().aggregate;
  const [filters] = useDashFilters();
  const [range] = useDateRange();
  const currency = useCurrency();
  const dashboardShouldLoad = useDashboardShouldLoad();
  const billingMonths = toBillingMonths(range);
  const setError = useSetDashboardMigrationError();
  // This is used to make sure that charts for a specific collector only show data for that individual collector
  const configuredFilters = handleFiltersForDatasource(segment.collectorId, filters);

  return useDataClientPromise(
    queryService.query,
    [
      [
        {
          arg: argument?.id === "date" ? "BILLING_MONTH" : argument?.id,
          limit: limit,
          includeOthers: includeOthers!!,
          segment: segment.id,
          orderBy: orderBy ?? QueryDimensionOrderByEnum.COST,
        },
      ],
      [{ dimension: "BILLING_MONTH", filters: billingMonths }, ...(configuredFilters ?? [])],
      currency,
    ],
    {
      useCacheKey: "query",
      shouldLoad: dashboardShouldLoad,
      onError: setError ? () => setError(true) : undefined,
      overrideCall: configuredFilters === null ? () => [] : undefined,
    }
  );
}

const handleFiltersForDatasource = (
  collectorId: string | undefined,
  filters: GroupSegmentFilter[]
): QueryFilter[] | null => {
  const mappedFilters = filters
    .filter((filter) => filter.filters.length > 0)
    .map((filter) => ({ dimension: filter.segment.id, filters: filter.filters.map((it) => it.id) }));

  // No collector specific attribute, just return mapped filters
  if (!collectorId) {
    return mappedFilters;
  }

  const attributeFilter = { dimension: "DATASOURCE", filters: [collectorId] };
  const dsFilter = mappedFilters.find((it) => it.dimension === "DATASOURCE");
  // if no datasource filter is available, add attributeQueryFilter
  if (!dsFilter) {
    return [attributeFilter, ...mappedFilters];
  }
  // else if datasource filter is added, and attributeId is in there replace with AttributeQueryFilter
  if (dsFilter.filters.includes(collectorId)) {
    return mappedFilters.map((it) => (it.dimension === "DATASOURCE" ? attributeFilter : it));
  }
  // else return empty results
  return null;
};

export function useRangeAsFilter(): QueryFilter {
  const [range] = useDateRange();
  const billingMonths = toBillingMonths(range);

  return { dimension: DIMENSIONS.BILLING_MONTH, filters: billingMonths };
}

export function useLineItemsForPivot(segments: string[], values: string[]) {
  const queryService = useServices().aggregate;
  const [filters] = useDashFilters();
  const [range] = useDateRange();
  const currency = useCurrency();
  const billingMonths = toBillingMonths(range);

  return useDataClientPromise(
    queryService.segments,
    [
      segments,
      values,
      [
        { dimension: "BILLING_MONTH", filters: billingMonths },
        ...filters
          .filter((filter) => filter.filters.length > 0)
          .map((filter) => ({ dimension: filter.segment.id, filters: filter.filters.map((it) => it.id) })),
      ],
      currency,
    ],
    {
      useCacheKey: "pivot",
      shouldLoad: true,
    }
  );
}

export function aggregateToChart(
  displaySegment: LineItemDisplay[],
  items?: LineItemQueryResponseDTO,
  xAxis?: string[]
): [CFDataSource[], CFSeries[]] {
  if (!items) {
    return [[], []];
  }
  const series: Record<string, string> = {};

  const args = xAxis
    ? xAxis.map((it) => {
        const actual = items.arguments.find((a) => a.argId === it);
        if (actual) {
          return { ...actual };
        }
        return {
          argId: it,
          argDisplay: it,
          points: [],
        };
      })
    : items.arguments;

  let prevPoints: Record<string, boolean> = {};
  const dataSource: CFDataSource[] = args
    .sort((a, b) => a.argId.localeCompare(b.argId))
    .map((arg) => {
      const res: CFDataSource = {
        argument: arg.argId,
      };
      const currPoints = { ...prevPoints };
      arg.points.forEach((point) => {
        // Handle updating which 0 values need to be set:
        prevPoints[point.key] = true;
        delete currPoints[point.key];

        // Handle updating set values:
        displaySegment.forEach((display) => {
          const key = `${point.key}${display.value}`;
          res[key] = point.value[display.value] || 0;
          if (!series[key]) {
            series[key] = escapeHtml(
              displaySegment.length > 1 ? `${point.keyDisplay} (${display.label})` : point.keyDisplay || point.key
            );
          }
        });
      });
      // Set empty keys
      Object.keys(currPoints).forEach((pointKey) => {
        displaySegment.forEach((display) => {
          const key = `${pointKey}${display.value}`;
          res[key] = 0;
        });
      });
      return res;
    });

  return [dataSource, Object.entries(series).map((it) => ({ key: it[0], label: it[1] }))];
}

export function segmentTotal(displaySegment: LineItemDisplay, items?: LineItemQueryResponseDTO[] | null): CFTotal[] {
  if (!items || items.length !== 1) {
    return [];
  }
  const res: Record<string, CFTotal> = {};
  items[0].arguments.forEach((arg) => {
    arg.points.forEach((point) => {
      if (!res[point.key]) {
        res[point.key] = { id: point.key, display: point.keyDisplay || point.key, value: 0 };
      }
      res[point.key].value += point.value[displaySegment.value] ?? 0;
    });
  });
  return Object.values(res);
}

export function segmentPerArgument(
  displaySegment: LineItemDisplay,
  items?: LineItemQueryResponseDTO[] | null
): CFPerArgument[] {
  if (!items || items.length !== 1) {
    return [];
  }
  return items[0].arguments.map((arg) => ({
    id: arg.argId,
    display: arg.argDisplay || arg.argId,
    values: arg.points.map((point) => ({
      id: point.key,
      display: point.keyDisplay || point.key,
      value: point.value[displaySegment.value] || 0,
    })),
  }));
}

export type CFTotal = {
  id: string;
  display: string;
  value: number;
};

export type CFPerArgument = {
  id: string;
  display: string;
  values: CFTotal[];
};

type CFDataSource = {
  argument: string;
  [key: string]: number | string;
};

type CFSeries = {
  key: string;
  label: string;
};
